import React, { useEffect, useState } from 'react'
import Wrapper, { StepProps } from './Wrapper'
import { Button, makeStyles, Typography } from '@material-ui/core'
import allSkills, { SkillType } from '../data/Skills'
import { PonyIdType, PonyType } from '../data/Pony'
import MyMarkdown from '../layout/MyMarkdown'

type Props = {
  skill?: SkillType
  skillOption?: string
  pony?: PonyType
  setSkill: (skill: SkillType) => void
  setSkillOption: (skillOption?: string) => void
} & StepProps

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: "center",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(0, 2, 2, 0),
  }
}))

function SkillStep({skill: parentSkill, setSkill: setParentSkill, skillOption: parentSkillOption, setSkillOption: setParentSkillOption, pony, ...props} : Props) {
  const [skill, setSkill] = useState(parentSkill)
  useEffect(() => {
    setSkill(parentSkill)
  }, [parentSkill, setSkill])
  const [skillOption, setSkillOption] = useState(parentSkillOption)
  useEffect(() => {
    setSkillOption(parentSkillOption)
  }, [parentSkillOption, setSkillOption])

  const classes = useStyles()
  const skills = allSkills.filter(skill => !skill.requires || (pony?.id === skill.requires))

  const isValid = skill !== undefined && skills.some(theSkill => theSkill.name === skill.name) && (skill.options === undefined || skillOption !== undefined)
  const onSave = () => {
    if (skill === undefined || !skills.some(theSkill => theSkill.name === skill.name)) { return false }
    setParentSkill(skill)
    setParentSkillOption(skillOption)
    return true
  }

  return (<Wrapper label="Begabung" {...props} isValid={isValid} onSave={onSave}>
    <Typography paragraph>Eine Begabung ist eine Fertigkeit oder ein Talent oder etwas, mit dem du dich wirklich gut auskennst. Tatsächlich
bist du sogar so gut darin, dass es dich unter deinen Freunden und den meisten anderen Ponys hervorstechen
lässt.</Typography>
<Typography paragraph>Die Hauptbegabung deines Ponys ist jene, die du entdeckt hast, kurz bevor du deinen Schönheitsfleck bekommen
hast. Als du herausgefunden hast, was deine Begabung ist, ist dein Schönheitsfleck auf magische Weise
erschienen. Diese Begabung ist die eine, die dein Pony am meisten beeinflusst – sie definiert oft die Aufgabe oder
das Lebensziel eines Ponys. Wenn du zum Beispiel herausfindest, dass du die Gabe hast, andere zu heilen, und du
das Gefühl hast, dass du dich mehr als alles andere mit Heilung und Medizin beschäftigen willst, dann wird dies
deine Begabung sein und dein Schönheitsfleck spiegelt dies wider.</Typography>
<Typography paragraph>
Alle erwachsenen Ponys haben eine Schönheitsfleck-Begabung, und unser Spiel beginnt kurz nachdem dein Pony
seine erhalten hat.</Typography>
    <div className={classes.buttonContainer}>
      {skills.map(theSkill => {
        return (<Button className={classes.button} key={theSkill.name} onClick={() => {setSkill(theSkill); setSkillOption(undefined)}} color={skill?.name === theSkill.name ? "primary" : "inherit"}>{theSkill.name}</Button>)
      })}
    </div>

    {skill && <div>
      <Requirement ponyId={skill.requires} />
      {skill.isInitial && <Typography paragraph><strong>Alle {skill.requires === "earth" ? "Erdponies" : (skill.requires === "pegasi" ? "Pegasi" : (skill.requires === "unicorn" ? "Einhörner": "Ponies"))} haben
        diese Begabung. Wenn du sie auswählst, bist du aber <em>besonders</em> gut darin.</strong></Typography>}
      <MyMarkdown>{skill.description}</MyMarkdown>
      {skill.options && 
        <div className={classes.buttonContainer}>
          {skill.options.map(option => {
            return (<Button size="small" className={classes.button} key={option} onClick={() => {setSkillOption(option)}} color={skillOption === option ? "primary" : "inherit"}>{option}</Button>)
          })}
        </div>
      }
    </div>}
  </Wrapper>)
}

function Requirement({ponyId} : {ponyId?: PonyIdType}) {
  if (ponyId === "earth") {
    return <Typography paragraph variant="h4">Nur für Erdponies</Typography>
  } else if (ponyId === "pegasi") {
    return <Typography paragraph variant="h4">Nur für Pegasi</Typography>
  } else if (ponyId === "unicorn") {
    return <Typography paragraph variant="h4">Nur für Einhörner</Typography>
  } else {
    return null
  }

}

export default SkillStep
