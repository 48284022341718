export type QuirkType = {
  name: string
  description: string
}

const allQuirks: QuirkType[] = [
  {
    name: "Albern",
    description: `Wenn du zu albern wirst, finden dich die ernsthafteren deiner Freunde nervig. Nicht alle Ponys in Equestria haben
    etwas übrig für Mätzchen und Streiche, erst recht nicht wenn sie versuchen, etwas zu erledigen. Manchmal hast du
    Schwierigkeiten, zu erkennen, ob es der richtige Zeitpunkt für einen albernen Witz oder ernsthaftes Schweigen ist.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Allergie",
    description: `Hatschi! Ob nun gegen Drachenniesbäume oder Bienen, du bist allergisch gegen etwas, das dafür sorgt, dass du
    dich krank fühlst, und dich niesen lässt. Wenn deine Allergien sehr schlimm sind, solltest du vielleicht besser ein
    bisschen Ponybalsam für den Notfall dabei haben.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Angst",
    description: `Ein Pony mit dieser Eigenheit erschreckt sich vor allen möglichen Dingen oder hat vielleicht auch nur große Angst
    vor einer Sache. Vielleicht vor Schlangen, vielleicht vor großen Höhen, aber egal was es ist, es lässt dich bis ins
    Mark erschauern und sorgt dafür, dass sich deine Mähne aufstellt. Diese Eigenheit darf mehrfach gewählt werden,
    aber jedes Mal musst du dafür eine andere Angst aussuchen.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Asthma",
    description: `Wenn du diese Eigenheit hast, hast du manchmal Schwierigkeiten zu atmen, weil deine Atemwege sehr
    empfindlich sind. Ponys mit dieser Eigenheit haben meistens Ponybalsam bei sich, der die Symptome recht einfach
    lindern kann.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Braucht eine Brille",
    description: `Ein Pony, das eine Brille braucht, könnte kurz- oder weitsichtig sein, aber in jedem Fall fällt es ihm schwer, sich
    ohne seine Brille zurechtzufinden. Wenn du eine Brille brauchst, kannst du ohne sie andere Ponys vielleicht gar
    nicht auseinanderhalten, was zu interessanten Problemen führen könnte!`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Ichichichichich!",
    description: `Wo manche Ponys das Rampenlicht meiden, mögen es andere wiederum zu sehr und lieben es, im Mittelpunkt der
    Aufmerksamkeit zu stehen. Du kannst es vielleicht nicht leiden, wenn andere Ponys gelobt werden und du nicht,
    oder bemühst dich ganz besonders, um von jedem Pony bemerkt zu werden.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Kurze Aufmerksamkeitsspanne",
    description: `Ponys mit dieser Eigenheit fällt es schwer, sich länger auf eine einzige Sache zu konzentrieren. Es gibt so viele
    interessante Dinge in der Welt! Du wirst leicht von Vögeln oder Spielen abgelenkt, wenn du eigentlich hart
    arbeiten und für eine Prüfung lernen solltest, oder du fängst an, über ein mathematisches Problem nachzudenken,
    wenn du eigentlich auf das Hufballspiel achten solltest, das du gerade spielst!`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Kurzer Geduldsfaden",
    description: `Du kannst hin und wieder nicht anders, als zornig zu werden, und manchmal bist du es sogar die ganze Zeit! Mit
    dieser Eigenheit bist du hitzig, jähzornig und jederzeit bereit, an die Decke zu gehen. Manchmal fällt es dir schwer,
    dich daran zu erinnern, wer deine Freunde sind, aber mit ein wenig Geduld können sie dich immer daran erinnern.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Naiv",
    description: `Du siehst in jedem Pony immer nur das Beste, was natürlich etwas Großartiges ist, aber es kann auch zu
    Situationen führen, in denen dich ein Pony ausnutzt. Dein Pony ist besonders anfällig für Shirelatane und
    Schwindlerponys.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Oooohhh... Wie das glänzt!",
    description: `Ponys mit dieser Eigenheit werden gelegentlich von Habgier und dem Verlangen nach glänzenden Dingen
    gepackt, die sie lieben – egal ob Edelsteine oder alte, modische Kleider ... Du musst dich mit deinem SL darauf
    einigen, was dein Pony so sehr mag.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Putzteufel",
    description: `Du liebst Ordnung und Perfektion und kannst es nicht ertragen, wenn die Dinge nicht absolut perfekt sind. Dies
    kann zu Schwierigkeiten führen, wenn sich andere Ponys weniger um Perfektion bemühen als du oder wenn sie
    einen anderen Stil haben als dein Pony.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Rechthaberisch",
    description: `Vielleicht bist du wirklich ein guter Anführer, aber du bist auch der Meinung, dass du der einzige sein solltest,
    der die Entscheidungen trifft. Ein Pony mit dieser Eigenheit tut sich schwer damit, seine Freunde ihre eigenen
    Entscheidungen treffen zu lassen, und kann es nicht ausstehen, sich hilflos zu fühlen.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Schlampig",
    description: `Dir macht der Mist nichts aus und manchmal vergisst du es, ihn sofort abzuwaschen – und deine Mähne ist auch
    immer durcheinander! Andere Ponys fühlen sich in deiner Nähe mitunter ein bisschen unwohl, außer du bemühst
    dich darum, aufzuräumen und dich sauber zu halten, besonders wenn sie Putzteufel sind!`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Superschüchtern",
    description: `Du leidest unter übermäßiger Schüchternheit, bist recht leicht zu bequatschen und lässt dich leicht von gemeinen
    Wesen herumschubsen. Es ist dir unangenehm, vor anderen zu sprechen, und kannst Aufmerksamkeit ganz
    allgemein nicht ausstehen, daher erstarrst du unter solchen Umständen immer, auch wenn etwas Wichtiges auf
    dem Spiel steht.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Ungehobelt",
    description: `Es stimmt, dass Ehrlichkeit wichtig ist, aber genauso wichtig ist es, auf die Gefühle anderer zu achten. Mit dieser
    Eigenheit sagst du frei heraus, was du denkst, auch wenn es dir nicht unbedingt weiterhilft und vielleicht die
    Gefühle eines Ponys verletzt.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Vergesslich",
    description: `Du würdest deine Hufeisen vergessen, wenn sie nicht an deinen Füßen befestigt wären! Wenn du diese Eigenheit
    hast, vergisst du oft wichtige Informationen. Man kann sich nicht auf
    dich verlassen, wenn du zum Beispiel ein Seil zu einem Kletterabenteuer
    mitbringen sollst, außer du schreibst es dir sorgfältig auf.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Zu selbstbewusst",
    description: `Wettläufe, Mode oder Tanz –
    was immer es ist, du weißt,
    dass du der oder die Beste
    bist, und forderst jeden heraus,
    das herauszufinden. Dies kann
    Probleme verursachen, wenn
    jemand anders besser ist als
    du, oder wenn du denkst,
    du könntest etwas schaffen,
    was du einfach nicht schaffen
    kannst.`.replace(/\n[ ]*/g, "\n")
  },
]

export default allQuirks
