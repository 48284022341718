import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Wrapper, { StepProps } from './Wrapper'

type Props = {
  name?: string
  setName: (name: string) => void
} & StepProps

function HelloStep({name: parentName, setName: setParentName, ...props}: Props) {
  const [name, setName] = useState(parentName)
  useEffect(() => {
    console.log("set")
    setName(parentName)
  }, [parentName, setName])

  const isValid = name !== undefined && name !== ""
  const onSave = () => {
    if (name === undefined || name === "") { return false }
    setParentName(name)
    return true
  }

  return (<Wrapper label="Los geht's" isValid={isValid} onSave={onSave} {...props}>
    <TextField label="Dein Vorname" value={name || ""} onChange={(e) => setName(e.target.value)} />
  </Wrapper>)
}

export default HelloStep
