import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core'
import React from 'react';
import "@fontsource/slackey"
import "@fontsource/roboto"
import "@fontsource/gaegu/700.css"
// @TODO: might need some variants
import "./equestria.css"
import "./gloria-hallelujah.css"
import { pink } from '@material-ui/core/colors';
import 'emoji-mart/css/emoji-mart.css'

const fontEquestria = '"Equestria", "Slackey", "Roboto", "Helvetica", "Arial", sans-serif'
const fontSlackey = '"Slackey", "Roboto", "Helvetica", "Arial", sans-serif'
const fontHandwritten = '"GloriaHallelujah", "Roboto", "Helvetica", "Arial", sans-serif'
const fontHandwrittenWeight = 400
const fontDefault = '"Roboto", "Helvetica", "Arial", sans-serif'
const baseSettings = {
  palette: {
    type: "light",
  },
  typography: {
    fontSize: 15,
    htmlFontSize: 15,
    fontFamily: fontDefault,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: fontEquestria,
      fontSize: "3rem",
      letterSpacing: "0",
      fontWeight: 300,
    },
    h2: {
      fontFamily: fontSlackey,
      fontSize: "2.5rem",
      letterSpacing: "0",
      fontWeight: 400,
    },
    h3: {
      fontFamily: fontSlackey,
      fontSize: "2rem",
      letterSpacing: "0",
      fontWeight: 400,
    },
    h4: {
      fontFamily: fontSlackey,
      fontSize: "1.5rem",
      letterSpacing: "0",
      fontWeight: 400,
    },
    h5: {
      fontSize: "1.25rem",
      letterSpacing: "0",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1rem",
      letterSpacing: "0",
      fontWeight: 600,
    },
    body2: {
      fontSize: "2rem",
      letterSpacing: "0",
      fontFamily: fontHandwritten,
      fontWeight: fontHandwrittenWeight,
      lineHeight: "1em",
      color: "rgba(0,0,0,0.84)"
    },
  },
  props: {
    MuiButton: {
      variant: "contained"
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: "url(/images/bg.svg)",
          backgroundSize: "100% auto",
          backgroundPosition: "-20vw -20vh",
        },
        '#root': {
          minHeight: '100vh',
        },
      }
    },
    MuiButton: {
      label: {
        fontFamily: fontSlackey,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: fontHandwritten,
        fontWeight: fontHandwrittenWeight,
        fontSize: "2rem",
        lineHeight: "1rem",
      },
      input: {
        height: "1em",
      }
    },

    MuiSlider: {
      root: {
        width: "calc(100% - 36px)",
        height: "16px",
        padding: "24px 0",
        margin: "0 18px",
      },
      rail: {
        height: "16px",
      },
      track: {
        height: "16px",
      },
      mark: {
        height: "16px",
      },
      thumb: {
        width: "32px",
        height: "48px",
        marginLeft: "-17px",
        marginTop: "-16px",
        borderRadius: "16px",
      },
      valueLabel: {
        left: 0,
        fontSize: "2rem",
        color: "transparent",
        top: "calc(50% - 0.5rem)",
        transition: "none",
        transformOrigin: "unset",
      },
    },

    
  },

}

type MyThemeProps = {
  primary: any
  children: React.ReactNode
}

function MyTheme({primary, children}: MyThemeProps) {
  const myTheme = createMuiTheme({
    palette: {
      background: {
        default: primary[300],
        paper: primary[100],
      },
      primary: {
        main: primary[900],
      }
    }
  }, baseSettings)

  return <ThemeProvider theme={myTheme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
}

export default MyTheme