import { blue, deepOrange, pink } from "@material-ui/core/colors"

export type PonyIdType = "earth" | "pegasi" | "unicorn"

export type PonyType = {
  id: PonyIdType,
  name: string
  abstract: string
  color: any
  description: string
}

const allPonies : PonyType[] = [
  {
    id: "earth",
    name: "Erdpony",
    abstract: "Ein Erdpony ist stark und mutig!",
    color: deepOrange,
    description: `Erdponys sind bekannt für ihre Stärke und dafür, mit allen vier Hufen auf dem
    Boden zu stehen. Sie bauen die meiste Nahrung in Equestria an. Erdponys haben
    eine Verbindung zur Natur und sind mit dem Land im Gleichgewicht. Sie sind
    unverzichtbar für die Erhaltung der Ordnung der Jahreszeiten. Im Herbst sorgen
    sie dafür, dass die Blätter fallen, und später treiben sie den Winter aus. So arbeiten
    sie unermüdlich, um dabei zu helfen, die Harmonie in Equestria zu bewahren. Viele
    Erdponys leben auf Farmen und in Dörfern auf dem Land, aber das bedeutet nicht,
    dass sie sich von großen Städten wie Mähnhattan und Fillydelphia fernhalten. Tatsächlich
    kann man Erdponys überall finden, wohin man in Equestria schaut, da ihre Robustheit ihnen
    ermöglicht, sogar an Orten leben zu können, die andere Ponys karge Ödlande nennen würden!

    Das tapfere Herz eines Erdponys erlaubt ihnen, große Mühen zu überstehen, ohne nachzulassen, und bedeutet,
    dass sie ihren Freunden viel Liebe zu geben haben. Erdponycharaktere sind häufig die Stütze einer Ponygruppe,
    ein Fels, auf den sich andere Ponys verlassen können. Auch wenn sie manchmal als ein wenig „ungehobelt“ wirken
    mögen, sind sich Pegasi und Einhörner darüber einig, dass Erdponys die zuverlässigsten Freunde sind, die man
    sich nur wünschen kann`.replace(/\n[ ]*/g, "\n"),
  },
  {
    id: "pegasi",
    name: "Pegasus",
    abstract: "Ein Pegasus kann fliegen!",
    color: blue,
    description: `Pegasi sind die geflügelten Verwandten der Erdponys und Einhörner und sind
    bekannt für ihre Luftakrobatik und atemberaubende Wendigkeit. Ursprünglich
    waren die Pegasi ein Stamm von Kriegerponys, und sie sind noch heute eine große
    und noble Art der Ponys, die den Auftrag hat, sich um das Wetter in Equestria zu
    kümmern. Mit ihren Flügeln und ihren angeborenen Fähigkeiten können sie Wolken
    bewegen und Winde entstehen lassen. Auf diese Weise haben sie auch Cloudsdale
    erschaffen. Cloudsdale ist die fantastische Heimat vieler Pegasi und liegt hoch oben im
    Himmel. Dort lagern sie viele der Maschinen, die sie brauchen, um Schnee und Wolken
    zu erschaffen.

    Viele Pegasi lieben Sport, und sie haben viele Sportarten erfunden, bei denen sich auf Grund ihrer Flugfähigkeiten
    nur Pegasi miteinander messen können. Dies hat den Pegasi den Ruf eingebracht, diejenige Ponyart zu sein, die
    Wettkämpfe am meisten liebt. Tief in seinem Innern weiß jeder Pegasus allerdings, dass kein Wettkampf wichtiger
    ist als Freunde, und natürlich fliegen viele Pegasi auch sehr gerne in einer etwas langsameren Geschwindigkeit.
    Pegasuscharaktere können ihren Freunden häufig von oben helfen – ihre Fähigkeit zu fliegen kann in vielen
    Situationen sehr nützlich sein, ob beim Auskundschaften der Umgebung oder bei einem Wettlauf gegen die Zeit!
    Sie sind gut darin, rasch zu handeln, ihre Freunde vor Ärger zu warnen und, falls es nötig ist, sich von oben ins
    Gefecht zu stürzen! Pegasi können schnell Freundschaften schließen, und sie sind oft bereit, sich zum Schutz vor
    ihre Freunde zu stellen. Erdponys und Einhörner halten Pegasi manchmal für ein bisschen voreilig, ein bisschen zu
    versessen darauf, sich ins Getümmel zu stürzen, ohne vorher darüber nachzudenken – und das kann zu Problemen
    führen, wenn sie nicht alle Umstände kennen!`.replace(/\n[ ]*/g, "\n")
  },
  {
    id: "unicorn",
    name: "Einhorn",
    abstract: "Ein Einhorn kann Magie wirken!",
    color: pink,
    description: `Einhörner sind magisch und geheimnisvoll und durch das wunderhübsche Horn auf
    ihrer Stirn leicht von den andern Ponys in Equestria zu unterscheiden. Auch wenn
    das Horn wie jedes andere gewöhnliche Horn eingesetzt werden kann, ist sein
    Hauptzweck die mächtige Magie, für die Einhörner so berühmt sind. Die meisten
    Einhörner wenden Telekinese an – die Fähigkeit, Gegenstände mit der Kraft des
    Geistes zu bewegen – und einige andere können sich teleportieren, Gegenstände
    in andere Gegenstände verwandeln und starke Betäubungsstrahlen mit ihrem Horn
    verschießen!

    Ganz selten verdienen sich Einhörner einen Schönheitsfleck, der zeigt, dass ihre besondere
    Begabung die Magie selbst ist. Obwohl die meisten Einhörner nur ein bisschen Magie in
    Verbindung mit ihrem Schönheitsfleck kennen, haben solche Einhörner ein großes Wissen über die Magie und
    können zu den mächtigsten magischen Wesen in ganz Equestria werden.

    Viele Einhörner sind sehr stolz auf ihre Abstammung und werden oft als vornehm und majestätisch angesehen –
    oder ein bisschen arrogant, je nachdem, wen man fragt! Einhörner nutzen ihre Magie, um die Bewohner Equestrias
    zu beschützen, bei ihren Künsten zu helfen und all die Probleme zu lösen, die sich nicht mit alltäglichen Mitteln
    beheben lassen. Einhorncharaktere sind in einer Gruppe von Ponys auf Abenteuerfahrt sehr nützlich, denn sie
    können ihren Grips und ihre Magie nutzen, um bei den kniffligsten Rätseln und Problemen auszuhelfen. Viele
    reisende Einhornponys konzentrieren sich auf eine ihrer magischen Fähigkeiten, aber manche lernen viele Zauber,
    damit sie ihren Freunden in jeder Lage helfen können!`.replace(/\n[ ]*/g, "\n")
  },
]

export default allPonies
