import { fade, makeStyles } from '@material-ui/core'
import React from 'react'

type Props = {
  className?: string
  children: React.ReactNode
}

const useStyles = makeStyles(theme => ({
  root: {
    border: "4px solid " + theme.palette.primary.main,
    borderRadius: "8px",
    padding: theme.spacing(2, 2, 0, 2),
    maxWidth: theme.breakpoints.width("sm") / 2,
    margin: theme.spacing(0, "auto", 2),
    backgroundColor: fade(theme.palette.common.white, 0.1),
    boxShadow: "inset 0 0 20px 0px #fff",
  }
}))

function BorderBox({children, className}: Props) {
  const classes = useStyles()
  return <div className={classes.root + " " + (className || "")}>{children}</div>
}

export default BorderBox
