import { Container, fade, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { Emoji } from 'emoji-mart'
import React from 'react'
import Help from '../components/Help'
import usePersistedState from '../hooks/usePersistentState'
import MyMarkdown from '../layout/MyMarkdown'
import Character from '../model/Character'

type Props = {
  character: Character
}

const useStylesForTableRow = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  label: {
    paddingRight: theme.spacing(2),
  },
  text: {
    textDecoration: "underline dashed " + fade(theme.palette.common.black, 0.5),
  },
}))

function TableRow({label, help, children}: {label: string, children: React.ReactNode, help?: React.ReactNode}) {
  const classes = useStylesForTableRow()

  return <Typography paragraph className={classes.root}>
    <Typography className={classes.label} color="primary" display="inline" variant="h4">{label}: </Typography>
    <Typography className={classes.text} display="inline" variant="body2">{children}</Typography>
    { help && <Help title={label}>{help}</Help>}
  </Typography>
}

function BeautyImage({character, className}: {character: Character, className?: string}) {
  const [beautyImage, _] = usePersistedState<string|undefined>('beautyImage')

  if (beautyImage) {
    return <img alt="" src={beautyImage} style={{maxWidth: "64px"}} className={className} />
  } else if(character.beautyEmoji) {
    return <span className={className}><Emoji emoji={character.beautyEmoji} size={64} /></span>
  } else {
    return <Typography paragraph variant="body2" className={className}>{character.beautyPatch || ""}</Typography>
  }

}

const useStyles = makeStyles(theme => ({
  info: {
    overflow: "hidden",
    padding: theme.spacing(2, 2, 0, 2),
  },
  center: {
    display: "block",
    textAlign: "center",
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
}))

function Basic({character}: Props) {
  const classes = useStyles()

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Paper className={classes.info}>
        <TableRow label="Ponyname">{character.ponyName}</TableRow>
        <TableRow label="Spielername">{character.playerName}</TableRow>
        <TableRow label="Ponyart" help={<MyMarkdown>{character.ponyType.description}</MyMarkdown>}>{character.ponyType.name}</TableRow>
        <TableRow label="Stufe">{character.level}</TableRow>
      </Paper>
    </Grid>
    <Grid item xs={6}>
      <Paper className={classes.info}>
        <Typography paragraph variant="h4" color="primary">Har&shy;mo&shy;nie<Help title={character.harmony.name}><MyMarkdown>{character.harmony.description}</MyMarkdown></Help></Typography>
        <div className={classes.center}>
          <img className={classes.mb} src={`/images/harmony/${character.harmony.id}_badge.png`} alt="" />
          <Typography paragraph variant="body2">{character.harmony.name}</Typography>
        </div>
      </Paper>
    </Grid>
    <Grid item xs={6}>
      <Paper className={classes.info}>
        <Typography paragraph variant="h4" color="primary">Schön&shy;heits&shy;fleck</Typography>
        <div className={classes.center}>
          <BeautyImage character={character} className={classes.mb} />
        </div>
      </Paper>
    </Grid>
  </Grid>
}

export default Basic
