import { Button, fade, IconButton, makeStyles, Popover, TextField, Typography } from '@material-ui/core'
import { Emoji, Picker } from 'emoji-mart'
import React, { useState } from 'react'
import { HarmonyType } from '../data/Harmony'
import DeleteIcon from '@material-ui/icons/Delete'
import BorderBox from '../layout/BorderBox'
import ImagePicker from './ImagePicker'

type Props = {
  harmony?: HarmonyType
  beautyPatch?: string,
  setBeautyPatch: (patch: string|undefined) => void
  emoji?: string
  setEmoji: (emoji: string|undefined) => void
  patchImage?: string
  setPatchImage: (dataUri: string|undefined) => void
}

const useStyles = makeStyles(theme => ({
  removeWrapper: {
    textAlign: "right",
  },
  root: {
    textAlign: "center",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  image: {
    maxWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  note: {
    color: fade(theme.palette.common.black, 0.5),
    fontSize: "0.75rem",
  },
}))

function BeautySelector({beautyPatch, setBeautyPatch, emoji, setEmoji, patchImage, setPatchImage, harmony}: Props) {
  const [emojiOpen, setEmojiOpen] = useState(false)
  const classes = useStyles()

  return <BorderBox className={classes.root}>
    { emoji === undefined && patchImage === undefined && <TextField className={classes.input} label="Beschreibe den Schönheitsfleck" value={beautyPatch || ""} onChange={(e) => setBeautyPatch(e.target.value)} /> }
    { emoji && patchImage === undefined && <div><Emoji emoji={emoji} size={64} /></div>}
    { patchImage && <img className={classes.image} src={patchImage} alt="" />}

    { emoji === undefined && beautyPatch === undefined && patchImage === undefined ? (<>
      <Typography paragraph>oder</Typography>
      <Button className={classes.button} onClick={() => {setEmojiOpen(true)}}>{emoji || "wähle ein Emoji"}</Button>
      <Popover 
        open={emojiOpen}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Picker 
          color={harmony?.color[900]}
          emoji={emoji}
          showSkinTones={false}
          showPreview={false}
          title="Patch Selector™"
          emojisToShowFilter={(emoji) => {
            const denyList = ["Man", "Woman", "Person", "Girl", "Boy", "Male", "Female", "Adult", "People", "Women", "Couple", "Car", "Automobile", "Airplane", "Clock", "Keycap", "Squared"]
            return denyList.every(name => !emoji.name.includes(name))
          }}
          onSelect={(emoji) => {setEmojiOpen(false); setEmoji(emoji.id)}} 
        />
      </Popover>
      <Typography paragraph>oder</Typography>
      <Button className={classes.button} variant="contained" component="label">Bild auswählen<ImagePicker setDataUri={setPatchImage} /></Button>
      <Typography className={classes.note} paragraph>am Besten 64px × 64px</Typography>
    </>) : ("") }
    { emoji !== undefined || beautyPatch !== undefined || patchImage !== undefined ? 
      <div className={classes.removeWrapper}><IconButton color="primary" onClick={() => {
        setBeautyPatch(undefined); 
        setEmoji(undefined)
        setPatchImage(undefined)
      }} aria-label="Entfernen"><DeleteIcon /></IconButton></div>
    : "" }
  </BorderBox>
}

export default BeautySelector
