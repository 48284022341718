import React, { useEffect, useState } from 'react'
import Wrapper, { StepProps } from './Wrapper'
import { Button, makeStyles, Typography } from '@material-ui/core'
import allQuirks, { QuirkType } from '../data/Quirks'
import MyMarkdown from '../layout/MyMarkdown'

type Props = {
  quirk?: QuirkType
  setQuirk: (quirk: QuirkType) => void
} & StepProps

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: "center",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(0, 2, 2, 0),
  }
}))

function QuirkStep({quirk: parentQuirk, setQuirk: setParentQuirk, ...props} : Props) {
  const [quirk, setQuirk] = useState(parentQuirk)
  useEffect(() => {
    setQuirk(parentQuirk)
  }, [setQuirk, parentQuirk])

  const isValid = quirk !== undefined
  const onSave = () => {
    if (quirk === undefined) { return false }
    setParentQuirk(quirk)
    return true
  }

  const classes = useStyles()
  return (<Wrapper label="Eigenheit" {...props} isValid={isValid} onSave={onSave}>
    <Typography paragraph>Eigenheiten sind wie Begabungen, aber sie wirken sich schlecht auf dein Pony aus.</Typography>
    <Typography paragraph>Eigenheiten sind sehr wichtig, weil sie deinen Ponycharakter interessanter machen – niemand ist perfekt, und es ist
großartig, dass du dich auf deine Freunde verlassen kannst, damit sie dir über deine Eigenheiten hinweg helfen,
genau wie du ihnen mit ihren hilfst.</Typography>
<Typography paragraph>Eigenheiten sind nicht nur schlecht: Wenn eine Eigenheit ein Pony davon abhält, etwas Wichtiges zu tun, gibt der
SL dem Spieler einen Freundschaftsstein – deine Freundschaften werden ebenso durch deine Eigenheiten wie
durch deine Stärken zu etwas so Besonderem!</Typography>
<Typography paragraph>Wenn der SL diese Regel erlaubt, kannst du entscheiden, dir noch eine weitere Eigenheit zu nehmen, wenn du
deinen Ponycharakter erschaffst – suche dir eine zusätzliche Eigenheit aus, wie oben beschrieben, und schreibe sie
unter deiner Haupteigenheit auf.</Typography>
    <div className={classes.buttonContainer}>
      {allQuirks.map(theQuirk => {
        return (<Button className={classes.button} key={theQuirk.name} onClick={() => setQuirk(theQuirk)} color={quirk?.name === theQuirk.name ? "primary" : "inherit"}>{theQuirk.name}</Button>)
      })}
    </div>

    {quirk && <div>
      <MyMarkdown>{quirk.description}</MyMarkdown>
    </div>}
  </Wrapper>)
}

export default QuirkStep
