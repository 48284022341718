import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Paper, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React, {  } from 'react'
import MyMarkdown from '../layout/MyMarkdown'
import Character, { Skill as SkillType } from '../model/Character'

const useStylesForSkill = makeStyles(theme => ({
  dice: {
    width: "2em",
    marginRight: theme.spacing(2),
  },
  accordionRoot: {
    "&:before": {
      backgroundColor: "transparent",
    }
  }
}))

function Skill({children : skill}: {children: SkillType}) {
  const classes = useStylesForSkill()

  return <Accordion square={false} elevation={0} classes={{root: classes.accordionRoot}}>
    <AccordionSummary expandIcon={<ExpandMore />}><Typography variant="body2" display="inline" className={classes.dice}>W{skill.stat}</Typography> <Typography display="inline" variant="h4">{skill.name}</Typography></AccordionSummary>
    <AccordionDetails><MyMarkdown>{skill.description}</MyMarkdown></AccordionDetails>
  </Accordion>
}

type Props = {
  character: Character
}

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    padding: theme.spacing(2, 2, 0, 2),
  },
}))

function Skills({character}: Props) {
  const classes = useStyles()
  
  return <Paper className={classes.root}>
    <Typography variant="h4" color="primary" paragraph>Begabungen</Typography>
    {character.skills.map(skill => <Skill key={skill.name}>{skill}</Skill>)}  
  </Paper>
}

export default Skills
