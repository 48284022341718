import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  img: {
    border: theme.spacing(1) + "px solid #fff",
    borderRadius: theme.spacing(2),
    maxWidth: "100%",
  }
}))

type Props = {
  src: string
  className?: string
}

function MyImage({src, className}: Props) {
  const classes = useStyles()

  return <img src={src} alt="" className={classes.img + " " + (className || "")} />
}

export default MyImage
