import React, { useEffect } from 'react'

// @see https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
function usePersistedState<T>(key: string, defaultValue?: T) : [T, React.Dispatch<T>] {
  const [state, setState] = React.useState(
    () => {
      const saved = localStorage.getItem(key)
      try {
        return typeof(saved) === "string" ? JSON.parse(saved) : defaultValue
      } catch (e) {
        return defaultValue
      }
    }
  );
  useEffect(() => {
    if (state === undefined) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);
  return [state, setState];
}

export default usePersistedState
