import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Paper, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React, {  } from 'react'
import { QuirkType } from '../data/Quirks'
import MyMarkdown from '../layout/MyMarkdown'
import Character, {  } from '../model/Character'

const useStylesForQuirk = makeStyles(theme => ({
  accordionRoot: {
    "&:before": {
      backgroundColor: "transparent",
    }
  }
}))

function Quirk({children : quirk}: {children: QuirkType}) {
  const classes = useStylesForQuirk()

  return <Accordion square={false} elevation={0} classes={{root: classes.accordionRoot}}>
    <AccordionSummary expandIcon={<ExpandMore />}><Typography display="inline" variant="h4">{quirk.name}</Typography></AccordionSummary>
    <AccordionDetails><MyMarkdown>{quirk.description}</MyMarkdown></AccordionDetails>
  </Accordion>
}

type Props = {
  character: Character
}

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    padding: theme.spacing(2, 2, 0, 2),
  },
}))

function Quirks({character}: Props) {
  const classes = useStyles()
  return <Paper className={classes.root}>
    <Typography variant="h4" color="primary">Eigenheiten</Typography>
    {character.quirks.map(quirk => <Quirk key={quirk.name}>{quirk}</Quirk>)}
  </Paper>
}

export default Quirks
