import React, { useEffect, useState } from 'react'
import Wrapper, { StepProps } from './Wrapper'
import { Button, makeStyles, Typography } from '@material-ui/core'

type Props = {
  isStrong?: boolean
  setStrong: (isStrong: boolean) => void
} & StepProps

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: "center",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(0, 2, 2, 0),
  }
}))

function StrongStep({isStrong: originalIsStrong, setStrong: setParentStrong, ...props} : Props) {
  const [isStrong, setIsStrong] = useState(originalIsStrong)

  useEffect(() => {
    setIsStrong(originalIsStrong)
  }, [originalIsStrong, setIsStrong])

  const isValid = isStrong !== undefined
  const onSave = () => {
    if (isStrong === undefined) { return false }
    setParentStrong(isStrong)
    return true
  }

  const classes = useStyles()
  
  return (<Wrapper label="Fokus" {...props} isValid={isValid} onSave={onSave}>
    <Typography paragraph>Wird dein Pony stark, mutig und entschlossen sein und gut rennen, klettern und schwere Dinge hochheben können?
Oder möchtest du, dass dein Pony schlau ist und besser nachdenken, lernen und Rätsel lösen kann?</Typography>
    <div className={classes.buttonContainer}>
      <Button className={classes.button} onClick={() => setIsStrong(true)} color={isStrong === true ? "primary" : "inherit"}>Stark</Button>
      <Button className={classes.button} onClick={() => setIsStrong(false)} color={isStrong === false ? "primary" : "inherit"}>Schlau</Button>
    </div>
  </Wrapper>)
}

export default StrongStep
