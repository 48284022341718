import { Button, fade, IconButton, makeStyles, Popover, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ExternalLink from '../components/ExternalLink'
import { HarmonyType } from '../data/Harmony'
import { QuirkType } from '../data/Quirks'
import { SkillType } from '../data/Skills'
import Wrapper, { StepProps } from './Wrapper'
import { Picker } from 'emoji-mart'
import BeautySelector from '../components/BeautySelector'
import BorderBox from '../layout/BorderBox'
import ImagePicker from '../components/ImagePicker'
import DeleteIcon from '@material-ui/icons/Delete'
import { PonyType } from '../data/Pony'

type Props = {
  name?: string
  setName: (name: string) => void
  beautyPatch?: string
  setBeautyPatch: (beautyPatch?: string) => void
  beautyEmoji?: string
  setBeautyEmoji: (beautyEmoji?: string) => void
  beautyPatchImage?: string
  setBeautyPatchImage: (beautyPatchImage?: string) => void
  image?: string
  setImage: (image?: string) => void
  skill?: SkillType
  skillOption?: string
  harmony?: HarmonyType
  quirk?: QuirkType
  ponyType?: PonyType
} & StepProps

const useStyles = makeStyles(theme => ({
  input: {
    marginBottom: theme.spacing(2),
  },
  borderBox: {
    textAlign: "center",
  },
  removeWrapper: {
    textAlign: "right",
  },
  image: {
    maxWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  note: {
    color: fade(theme.palette.common.black, 0.5),
    fontSize: "0.75rem",
  },
}))

function FinalStep({
  name: parentName, 
  setName: setParentName, 
  beautyPatch: parentBeautyPatch, 
  setBeautyPatch: setParentBeautyPatch, 
  beautyEmoji: parentBeautyEmoji,
  setBeautyEmoji: setParentBeautyEmoji, 
  beautyPatchImage: parentBeautyPatchImage,
  setBeautyPatchImage: setParentBeautyPatchImage,
  image: parentImage,
  setImage: setParentImage,
  skill, 
  skillOption, 
  harmony, 
  quirk, 
  ponyType,
  ...props}: Props
) {
  const [name, setName] = useState(parentName)
  useEffect(() => {
    setName(parentName)
  }, [parentName, setName])
  const [beautyPatch, setBeautyPatch] = useState(parentBeautyPatch)
  useEffect(() => {
    setBeautyPatch(parentBeautyPatch)
  }, [parentBeautyPatch, setBeautyPatch])
  const [emoji, setEmoji] = useState<string>()
  useEffect(() => {
    setEmoji(parentBeautyEmoji)
  }, [parentBeautyEmoji, setEmoji])
  const [beautyPatchImage, setBeautyPatchImage] = useState<string>() // DataUri
  useEffect(() => {
    setBeautyPatchImage(parentBeautyPatchImage)
  }, [parentBeautyPatchImage, setBeautyPatchImage])
  const [image, setImage] = useState<string>() // DataUri
  useEffect(() => {
    setImage(parentImage)
  }, [parentImage, setImage])

  const isValid = name !== undefined && name !== "" && ((beautyPatch !== undefined && beautyPatch !== "") || beautyPatchImage !== undefined || emoji !== undefined)
  const onSave = () => {
    if (name === undefined) { return false }
    setParentName(name)

    if (beautyPatchImage) {
      setParentBeautyPatchImage(beautyPatchImage)
      setParentBeautyEmoji(undefined)
      setParentBeautyPatch(undefined)
    } else if (emoji) {
      setParentBeautyPatchImage(undefined)
      setParentBeautyEmoji(emoji)
      setParentBeautyPatch(undefined)
    } else if (beautyPatch) {
      setParentBeautyPatchImage(undefined)
      setParentBeautyEmoji(undefined)
      setParentBeautyPatch(beautyPatch)
    } else {
      return false
    }

    if (image) {
      setParentImage(image)
    } else {
      setParentImage(undefined)
    }

    return true
  }
  const classes = useStyles()

  return (<Wrapper label="Name &amp; Aussehen" isValid={isValid} onSave={onSave} {...props}>
    
    <Typography paragraph>Der Name, Schönheitsfleck und das Aussehen deines Ponys sind alle miteinander verbunden.
Zusammengenommen zeigen sie jedem klar und deutlich, wie dein Ponycharakter aussieht. Oft haben sie etwas
mit deiner Hauptbegabung{skill ? (<>, <strong>{skill.name}{skillOption ? (<> ({skillOption})</>) : ""}</strong>,</>) : ''} zu tun, aber das ist keine strenge Regel.
    </Typography>
    <Typography paragraph variant="h4">Name</Typography>
    <Typography paragraph>Der Name deines Ponys sollte leicht zu merken sein, er sollte spaßig und interessant sein und ein bisschen
vermitteln, wie dein Pony so ist. Es gibt keine festen Regeln für die Namenswahl für Ponys, aber du kannst dich
von der <ExternalLink href="https://mylittlepony.hasbro.com/de-de/characters/ponies">TV-Serie My Little Pony: Freundschaft ist Magie</ExternalLink> inspirieren lassen und den Spielleiter um Hilfe bitten.</Typography>
    <BorderBox className={classes.borderBox}>
      <TextField className={classes.input} label="Name deines Ponies" value={name || ""} onChange={(e) => setName(e.target.value)} />
    </BorderBox>

    <Typography paragraph variant="h4">Schönheitsfleck</Typography>
    <Typography paragraph>Der Schönheitsfleck deines Ponys ist normalerweise von deiner Begabung{skill ? (<>, <strong>{skill.name}{skillOption ? (<> ({skillOption})</>) : ""}</strong>,</>) : ''} inspiriert, aber auch hierfür gibt es keine
festen Regeln. Es kann mit deinem Namen{name ? <>, <strong>{name}</strong>,</> : ""} zusammenhängen oder deinem Element der Harmonie{harmony ? <>, <strong>{harmony.name}</strong>,</> : ""} oder etwas
anderem, das dabei hilft, deinen Ponycharakter einzigartig zu machen und seine Persönlichkeit zu beschreiben.
Auch hier kannst du die Schönheitsflecke der <ExternalLink href="https://mylittlepony.hasbro.com/de-de/characters/ponies">Charaktere in der TV-Serie</ExternalLink> als Inspirationsquelle nehmen und
schauen, wie sie zu den Begabungen, dem Namen oder der Persönlichkeit ihres Ponys passen.</Typography>
    
    <BeautySelector
      harmony={harmony}
      beautyPatch={beautyPatch}
      setBeautyPatch={(patch: string|undefined) => {
        if (patch === "") { patch = undefined }
        setBeautyPatch(patch)
        if (patch !== undefined) {
          setEmoji(undefined)
          setBeautyPatchImage(undefined)
        }
      }}
      emoji={emoji}
      setEmoji={(emoji: string|undefined) => {
        setEmoji(emoji)
        if (emoji !== undefined) {
          setBeautyPatch(undefined)
          setBeautyPatchImage(undefined)
        }
      }}
      patchImage={beautyPatchImage}
      setPatchImage={(dataUri: string|undefined) => {
        setBeautyPatchImage(dataUri)
        if (dataUri !== undefined) {
          setBeautyPatch(undefined)
          setEmoji(undefined)
        }
      }}
    />


    <Typography paragraph variant="h4">Portrait</Typography>
    <Typography paragraph>
      Wenn es an das Zeichnen deines Charakters geht, ist die Zeit um alle deine Lieblingsbuntstifte und Glitzerfarben auszupacken.
      Das Internet ist voller Malvorlagen, Ausmalbildern und Anleitungen, um ein Pony zu zeichnen. Spielleiter sind dazu angehalten
      das Zeichnen eines Ponies zu belohnen.
    </Typography>
    <Typography paragraph>
      Alternativ kann man auch auf einen der Online-Editoren zurück greifen und sich ein Pony zusammen klicken. Hier sind ein paar Möglichkeiten:
      <ul>
        <li><ExternalLink href="https://ponylumen.net/games/3d-pony-creator/">MLP Creator</ExternalLink></li>
        { ponyType?.id === "unicorn" && <li><ExternalLink href="https://www.cornify.com/younicorn/">cornify</ExternalLink></li> }
        { (ponyType?.id === "earth" || ponyType?.id === "pegasi") && <li><ExternalLink href="http://www.avatarsinpixels.com/pony">avatarsinpixels.com</ExternalLink></li> }
        <li><ExternalLink href="https://play.google.com/store/apps/details?id=com.bianf.avatars.pony.fantasy&amp;hl=en_SG">Avatar Maker: Fantasy Pony</ExternalLink> (Android App mit Werbung)</li>
      </ul>
    </Typography>
    { ponyType?.id === "earth" && <Typography paragraph><strong>Bitte denke daran, dass ein Erdpony weder Horn noch Flügel hat.</strong></Typography>}
    { ponyType?.id === "pegasi" && <Typography paragraph><strong>Vergiss nicht deinem Pegasus zwei wunderschöne Flügel zu zeichnen.</strong></Typography>}
    { ponyType?.id === "unicorn" && <Typography paragraph><strong>Vergiss nicht das Horn deines Einhorns.</strong></Typography>}
    <BorderBox className={classes.borderBox}>
      
      { image ? (<>
        <img src={image} alt="" className={classes.image} />
        <div className={classes.removeWrapper}><IconButton color="primary" onClick={() => {
          setImage(undefined)
        }} aria-label="Entfernen"><DeleteIcon /></IconButton></div> 
      </>) : (<>
        <Button className={classes.input} variant="contained" component="label">Bild auswählen<ImagePicker setDataUri={setImage} /></Button>
        <Typography className={classes.note} paragraph>am Besten quadratisch</Typography>
        <Typography className={classes.note} paragraph>du kannst auch später noch ein Bild hinzufügen</Typography>
      </>)}
    </BorderBox>
  </Wrapper>)
}

export default FinalStep
