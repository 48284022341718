import React from 'react';
import { AppBar, Container, fade, Grid, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MyTheme from './layout/MyTheme';
import { pink } from '@material-ui/core/colors';
import CharacterObj from './model/Character';
import Basic from './sheet/Basic';
import Stamina from './sheet/Stamina';
import Stats from './sheet/Stats';
import Friendship from './sheet/Friendship';
import Skills from './sheet/Skills';
import Quirks from './sheet/Quirks';
import Portrait from './sheet/Portrait';

import DeleteAll from './components/DeleteAll';
import Export from './components/Export';
import Import from './components/Import';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: theme.spacing(2,"auto"),
      boxShadow: "0 0 20px 0px #fff",
      borderRadius: "20px",
      overflow: "hidden",
      backgroundColor: fade(theme.palette.common.white, 0.5),
    },
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    grow: {
      flexGrow: 1
    },
  }
})

type Props = {
  character: CharacterObj
  onCharacterChange: (char : CharacterObj) => void
}

function Character({character, onCharacterChange}: Props) {
  const classes = useStyles()

  const handleStaminaChange = (newStamina: number) => {
    const newChar = character.clone()
    newChar.stamina = newStamina
    onCharacterChange(newChar)
  }

  const handleFriendshipChange = (newFriendship: number) => {
    const newChar = character.clone()
    newChar.friendship = newFriendship
    onCharacterChange(newChar)
  }

  return (<React.Fragment>
    <MyTheme primary={character.harmony?.color || pink}>
      <Container maxWidth="md" disableGutters={true} className={classes.container}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h1">{character.ponyName}</Typography>
            <div className={classes.grow}></div>
            <Export />
            <DeleteAll />
          </Toolbar>
        </AppBar>
        <Container className={classes.content}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item sm={6} xs={12}>
            <Basic character={character} />
          </Grid>
          <Grid item sm={6} xs={12}><Portrait /></Grid>
          <Grid item sm={12} xs={12}><Stamina character={character} onStaminaChange={handleStaminaChange} /></Grid>
          <Grid item sm={8} xs={12}><Stats character={character} /></Grid>
          <Grid item sm={4} xs={12}><Friendship character={character} onFriendshipChange={handleFriendshipChange} /></Grid>

          <Grid item sm={12} xs={12}><Skills character={character} /></Grid>
          <Grid item sm={12} xs={12}><Quirks character={character} /></Grid>
        </Grid>
        </Container>
      </Container>
    </MyTheme>
      
    </React.Fragment>
  )
}

export default Character;
