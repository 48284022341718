import { PonyIdType } from "./Pony"

export type SkillType = {
  name: string
  description: string
  isInitial?: boolean
  options?: string[]
  requires?: PonyIdType
}

const allSkills: SkillType[] = [
  {
    name: "Alleskönnerpony",
    requires: "earth",
    description: `Manche Ponys sind Meister ihres Handwerks und konzentrieren sich auf ihre Kunst, bis sie den Gipfel ihres Könnens und
    ihrer Begabung erreicht haben. Andere entscheiden stattdessen, in vielen verschiedenen Dingen gut zu sein; nicht ganz
    so spezialisiert, dafür aber vielseitig! Immer wenn du eine Probe ablegst, darfst du deinen Würfel für Alleskönnerpony
    zusätzlich zu dem anderen Würfel werfen und dir eins der Ergebnisse aussuchen. Wenn du dich für den Würfel für
    Alleskönnerpony entscheidest, kannst du ihn für keine weitere Probe während dieser Spielsitzung mehr benutzen.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Betäubungsstrahl",
    requires: "unicorn",
    description: `Wenn du diese Begabung nutzt, darfst du eine Herausforderung gegen ein Ziel ablegen, das du sehen kannst.
    Dabei wird deine Begabung Betäubungsstrahl gegen das Merkmal Körper des Ziels eingesetzt. Das Ziel verliert
    eine Anzahl Ausdauerpunkte in Höhe des Unterschieds, um den dein Wurf den Wurf des Ziels geschlagen
    hat. Wenn du aber eine 1 würfelst, geht der Betäubungsstrahl nach hinten los und du verlierst eine Anzahl
    Ausdauerpunkte in Höhe des Unterschieds, um den der Wurf des Ziels deinen geschlagen hat.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Fliegen",
    requires: "pegasi",
    isInitial: true,
    description: `Diese Begabung erlaubt dem Pony zu fliegen. Proben und Herausforderungen, die mit dem Fliegen zu tun haben,
    verwenden den Würfelwert dieser Begabung (also würde ein Pony mit Fliegen W8 einen W8 für solche Proben
    und Herausforderungen benutzen). Je besser diese Begabung wird, desto schneller und wendiger kann das Pony
    fliegen. Der SL bestimmt die Schwierigkeit für Proben, die mit Fluggeschwindigkeit oder -können zu tun haben,
    und Herausforderung werden gegen die Begabung Fliegen der gegnerischen Charaktere abgelegt. Zum Beispiel
    könnte ein Wettfliegen gegen Fluttershy eine Herausforderung auf Fliegen gegen einen W6 sein, während ein
    Wettfliegen gegen Rainbow Dash oder einen der Wonderbolts eine Herausforderung auf Fliegen gegen einen
    W20 wäre.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Großes Wissen",
    description: `Du bist das Pony, an das man sich wendet, wenn man etwas zu einem bestimmten Thema wissen will – vielleicht
    weißt du alles über Äpfel, was man nur wissen kann, oder vielleicht bist du ein Experte für Geschichte. Wenn du
    dich für diese Begabung entscheidest, muss du ein Großes Wissen für dein Pony wählen. Weiter unten findest
    du einige Beispiele. Du kannst frei entscheiden, ob du etwas aus der Liste nehmen oder mit deinem SL sprechen
    willst, um dir deine eigene auszudenken.
    
    Immer wenn du eine Probe auf Geist ablegst und du und der SL der Meinung sind, dass
    dein jeweiliges Großes Wissen bei dieser Probe hilfreich wäre, wirfst du deinen Würfel für
    Großes Wissen zusätzlich zu deinem Geist-Würfel und suchst dir das höchste Ergebnis aus.
    Du darfst diese Begabung mehr als einmal nehmen, aber jedes Mal musst du dafür ein
    anderes Wissensgebiet wählen.`.replace(/\n[ ]*/g, "\n"),
    options: ["Biologie", "Chemie", "Geschichte", "Geografie", "Kunst", "Magiewissen", "Mathematik", "Physik", "Ponygesetze"]
  },
  {
    name: "Heilende Berührung",
    requires: "earth",
    description: `Manche Erdponys sind so mit der Lebenskraft anderer Wesen im Einklang,
    dass sie die erstaunliche Fähigkeit haben, ihre Freunde mit einer Berührung
    zu heilen. Einmal pro Spielsitzung kannst du deinen Würfel für Heilende
    Berührung werfen, um einem einzelnen Pony eine Anzahl Ausdauerpunkte in
    Höhe deines Wurfergebnisses zu geben. Das Pony kann nicht mehr Punkte
    erhalten, als seine Höchste Ausdauer angibt.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Kraftfeld",
    requires: "unicorn",
    description: `Manche Einhörner können ihre Kräfte nutzen, um magische Schilde zu erschaffen, die Kraftfelder genannt werden
    und die ihre Freunde vor Schaden bewahren können. Um ein Kraftfeld zu erschaffen, musst du dich konzentrieren
    können und entscheiden, wie groß das Kraftfeld sein soll.

    Der SL bestimmt die Schwierigkeit für eine Probe, um ein Kraftfeld zu erschaffen. Ein Kraftfeld um einen kleinen
    Gegenstand zum Beispiel hätte Schwierigkeit 4, eines, das einen Gegenstand von der Größe eines Ponys schützen
    soll, hätte Schwierigkeit 6 und eines, das ein Dorf abschirmen soll, hätte Schwierigkeit 12 oder mehr.
    
    Ein Kraftfeld hält so lange, wie sich das Pony, das es erschaffen hat, darauf konzentriert oder bis es durchbrochen
    wird.
    
    Wenn ein Charakter ein Kraftfeld durchbrechen will, muss er oder sie eine Probe auf sein oder ihr Körper-Merkmal
    ablegen, mit einer Schwierigkeit in Höhe der Würfelzahl der Begabung Kraftfeld des Ponys, das es erschaffen hat.
    
    Ein Pony, das beispielsweise ein Kraftfeld durchbrechen möchte, das ein Pony mit Kraftfeld W6 erschaffen hat,
    müsste also eine Probe auf Körper gegen Schwierigkeit 6 ablegen.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Kreatives Gespür",
    description: `Egal, ob es ums Backen, um Kunst oder Schauspiel geht, du bist hervorragend darin, diese Begabung vorzuführen.
    
    Wenn du dich für diese Begabung entscheidest, muss du ein Kreatives Gespür für dein Pony wählen. Weiter unten
    findest du einige Beispiele. Du kannst frei entscheiden, ob du etwas aus der Liste nehmen oder mit deinem SL
    sprechen willst, um dir deine eigene auszudenken.
    
    Immer wenn du eine Probe auf Geist ablegst und du und der SL der Meinung sind, dass dein Kreatives Gespür bei
    dieser Probe hilfreich wäre, wirfst du deinen Würfel für Kreatives Gespür zusätzlich zu deinem Geist-Würfel und
    suchst dir das höchste Ergebnis aus.`.replace(/\n[ ]*/g, "\n"),
    options: ["Bildhauerei", "Kochen", "Komödie", "Malerei", "Mode", "Musik", "Schauspiel"]
  },
  {
    name: "Mit Tieren sprechen",
    description: `Du bist so mit der Natur im Einklang, dass du mit Tieren sprechen und sie verstehen kannst. Wenn du diese
    Begabung das erste Mal bekommst, kannst du dir eine Tierart aus der unteren Liste aussuchen, und jedes Mal,
    wenn du sie aufwertest, kannst du eine weitere Art wählen.
    
    Mit dieser Begabung kannst du mit Tieren sprechen, die du dir ausgesucht hast, und dein Merkmal Charme
    einsetzen, um sie zu überzeugen.`.replace(/\n[ ]*/g, "\n"),
    options: ["Fische", "Insekten", "Mythenwesen", "Reptilien", "Säugetiere", "Vögel"],
  },
  {
    name: "Orten",
    requires: "unicorn",
    description: `Indem es sein Horn als Suchgerät einsetzt, kann das Einhorn Dinge finden, ohne seine gewöhnlichen Sinne zu
    verwenden. Wenn du diese Fertigkeit das erste Mal bekommst, kannst du dir eine neue Art Gegenstand aus der
    unteren Liste aussuchen, die du besonders gut orten kannst, und jedes Mal, wenn du sie aufwertest, kannst du
    einen weiteren wählen. Der Spielleiter bestimmt die Schwierigkeit für den Versuch, die Sache/den Gegenstand zu
    orten, je nachdem wie weit entfernt, wie selten und wie gut geschützt sie/er ist.`.replace(/\n[ ]*/g, "\n"),
    options: ["Edelsteine", "Feuer", "Holz", "Metall", "Wasser"],
  },
  {
    name: "Ponysinn",
    description: `Niemand weiß, warum dein Schweif zuckt, wenn etwas vom Himmel fällt, oder du Ohrenwedeln, Augenzucken
    oder Kniejucken bekommst, wenn du dich davor hüten musst, was sich hinter einer Tür befindet! Während einer
    Spielsitzung kannst du „spüren“, wie sich Schwierigkeiten zusammenbrauen, kurz bevor sie dich oder deine
    Freunde betreffen, und zwar so oft, wie dein Würfelwert in dieser Begabung angibt. Ponysinn kann dir nur eine
    unbestimmte Ahnung geben, keine bestimmte Voraussage der Zukunft.

    Wenn du zum Beispiel Ponysinn (W6) hast und dich zu Beginn der Spielsitzung ein Drache von hinter einem Felsen
    anspringt, um dich zu erschrecken, könntest du den SL fragen: „Kann ich versuchen, meinen Ponysinn zu nutzen,
    damit er mich vor dem Drachen warnt, bevor er auftaucht, damit ich nicht so überrascht bin?“ Der SL könnte
    antworten: „Sicher geht das. Die Schwierigkeit, den Drachen zu spüren, ist 4. Wirf deinen W6. Denke daran, dir
    aufzuschreiben, dass du nur noch 5 Anwendungen für den Ponysinn übrig hast.“
    
    Eine weitere Möglichkeit, wie der SL den Ponysinn einsetzen kann, ist, eine der Anwendungen zu nutzen, um diesem Pony
    zu sagen, was in naher Zukunft passieren könnte. Dies könnte eine großartige Gelegenheit für den SL sein, der Gruppe ein
    wenig Informationen zu liefern, wenn sie Schwierigkeiten haben, oder um die Geschichte weiter voranzubringen.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Starrer Blick",
    description: `Du hast die Fähigkeit, jemanden anzublicken ... _wirklich_ anzublicken. Wenn du den Starren Blick einsetzt, muss
    du mit deinem Ziel Augenkontakt halten und eine Herausforderung mit deiner Begabung Starrer Blick gegen das
    Geist-Merkmal des Ziels abglegen. Wenn du erfolgreich bist, erstarrt es und ist unfähig, irgendetwas zu tun. Wenn
    du einen erstaunlichen Erfolg erzielst, gehorcht es einfachen Anweisungen – aber sei nicht zu gemein! Der Starre
    Blick wirkt nur für eine kurze Zeit und kann nur einmal in jedem Abenteuer auf dasselbe Ziel angewandt werden.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Tapferes Herz",
    requires: "earth",
    isInitial: true,
    description: `Du bist stark, zäh und mutig. Nichts scheint dich entmutigen zu können und du kannst noch durchhalten, wenn
    andere Ponys schon aufgeben haben. Dein Start-Körper-Merkmal wird um eine Würfelkategorie aufgewertet, was
    auch bedeutet, dass deine Start-Ausdauer 12 statt 10 beträgt.

    Darüber hinaus bedeutet die Begabung Tapferes Herz auch, dass du einmal in jeder Spielsitzung den SL fragen
    kannst, ob du bei einer Probe oder Herausforderung auf dein Körper-Merkmal zusätzlich deinen Würfel für Tapferes
    Herz werfen darfst. Dies stellt dar, dass sich dein Pony noch mehr anstrengt, ohne den Mut zu verlieren – was
    Erdponys sehr widerstandsfähig und ausgezeichnet darin macht, Schwierigkeiten zu überwinden.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Telekinese",
    requires: "unicorn",
    isInitial: true,
    description: `Mit dieser Begabung kannst du Gegenstände mittels Magie bewegen. Je besser diese Begabung wird, desto
    schwerer sind auch die Gegenstände, die du anheben und bewegen kannst.
    
    Der SL bestimmt eine Schwierigkeit, die davon abhängt, wie schwer der Gegenstand ist, den du anheben willst.
    Beispielsweise einen Schlüsselring von einem Haken zu heben und sie zu deiner Gefängniszelle schweben zu
    lassen, könnte Schwierigkeit 3 haben, aber wenn du einen Schlüssel schweben lassen willst, während du von
    der anderen Seite einer Tür durch ein Schlüsselloch spähst, und dann den Schlüssel ins Schloss führen und
    anschließend drehen willst, könnte das Schwierigkeit 5 oder 6 haben. Ein einzelnes Pony langsam durch die Luft
    zu bewegen, wäre eine Probe mit Schwierigkeit 7, dasselbe mit einer Gruppe aus sechs Ponys zu machen, hätte
    Schwierigkeit 13 oder mehr. Nur die mächtigsten Ponys können so etwas vollbringen.
    
    Diese Begabung kann von dem Einhorn auch genutzt werden, um Licht aus seinem Horn leuchten zu lassen,
    ohne einen Gegenstand zu bewegen. Mit einem Wert von W6 entspricht das Licht dem einer Laterne, aber auf
    höheren Stufen kann es noch heller werden und der SL kann dir erlauben, es zu benutzen, um manche Wesen
    vorübergehend zu lähmen, besonders die, die starkes Licht nicht gewöhnt sind.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Teleportieren",
    requires: "unicorn",
    description: `Du kannst dich selbst mit Hilfe deiner Einhornmagie über kurze Entfernungen teleportieren. Teleportierung lässt
    sich nur schwer kontrollieren und kann manchmal mehr Probleme verursachen, als sie löst! Dich selbst über eine
    Entfernung von einem guten Meter zu teleportieren, hat Schwierigkeit 5, und jeder weitere Meter darüber hinaus
    addiert zusätzlich 1 auf die Schwierigkeit. Wenn dir eine Probe auf Teleportieren misslingt, bedeutet das, dass du dich
    an einen Ort teleportiert hast, an den du gar nicht wolltest, auch wenn es dich vielleicht trotzdem vor Schwierigkeiten
    in Sicherheit gebracht hat. Die grundlegende Schwierigkeit 5 selbst für einen kurzen Teleport bedeutet, dass du diese
    Begabung mindestens auf W6 aufwerten solltest, bevor du überhaupt versuchst, etwas zu teleportieren.`.replace(/\n[ ]*/g, "\n")
  },
  {
    name: "Wolkentreiben",
    requires: "pegasi",
    description: `Pegasi haben die sehr wichtige Pflicht, dafür zu sorgen, dass das Wetter in Equestria nicht außer Kontrolle
    gerät. Sie tun dies, indem sie Wolken dorthin bewegen, wo sie sein müssen, und benutzen sie, um es regnen
    zu lassen. Sie können sogar Tornados erschaffen und einen Sturm herbeirufen, wenn es nötig ist! Der SL setzt
    die Schwierigkeit für Proben an, die damit zu tun haben, Wolken fortzubewegen oder Regen zu machen. Eine
    einzelne Wolke fortzuschieben könnte nur Schwierigkeit 3 haben, einen ganzen Sturm zu beruhigen (oder einen zu
    erschaffen) könnte aber eher Schwierigkeit 10 haben – nur möglich für einen sehr talentierten Wolkentreiber.`.replace(/\n[ ]*/g, "\n")
  },
]

export default allSkills
