import { Typography } from '@material-ui/core'
import React from 'react'
import Wrapper, { StepProps } from './Wrapper'

type Props = {
  name?: string
} & StepProps

function IntroStep({name: playerName, ...props}: Props) {
  return (<Wrapper label="Intro" {...props}>
    <Typography paragraph variant="h5">Hallo {playerName}!</Typography>
    <Typography paragraph>
      Während du ein Pony erstellst, solltest du einige Fragen zu deinem Pony beantworten können. Diese Fragen
machen es dir leichter, zu verstehen, wie dein Pony denkt. Es könnte dir sehr ähnlich sein oder aber auch ganz
anders sein als du! Wirf einen Blick auf diese Fragen und schau, ob du sie alle beantworten kannst.</Typography>

<Typography paragraph><ul>
  <li>Was ist das Netteste, was dein Pony jeden Tag tut?</li>
  <li>Was ist der wertvollste Besitz deines Ponys und warum ist er ihm so wertvoll?</li>
  <li>Wem oder was ist dein Pony besonders treu ergeben?</li>
  <li>Hat dein Pony ein Geheimnis?</li>
  <li>Was lässt dein Pony laut lachen?</li>
  <li>Wer sind die Familie und Freunde deines Ponys und wo leben sie?</li>
</ul></Typography>
<Typography paragraph variant="h6">Sei anders...</Typography>
<Typography paragraph>Equestria ist eine Welt, in der du fast jede Art von Pony sein kannst, die du sein willst, also hab keine Angst davor,
dich ein bisschen auszutoben. Vielleicht steht dein Pony auf Rock‘n‘Roll oder vielleicht mag es auch lieber ein
ruhiges Buch, auch wenn das nicht heißt, dass dein Pony nicht beides mögen kann. Wenn du deinen Ponycharakter
erstellst, versuche darauf zu achten, einem anderen Spieler nicht auf die Hufe zu treten – drei Einhornponys, die
alle auf Tanzen und Musik stehen, können spaßig sein, aber es kann noch viel spaßiger sein, wenn ihr euch alle
voneinander unterscheidet. Vielleicht bist du ja selbst eine Fluttershy und willst ausprobieren, wie Rainbow Dash
zu sein, oder vielleicht bist du ja auch eine Applejack, die einmal ausprobieren möchte, wie man sich als Fluttershy
fühlt? Dies ist deine Chance, um auszubrobieren, jemand anderes zu sein!</Typography>

<Typography paragraph variant="h6">... aber nicht zu anders</Typography>
<Typography paragraph>Davon abgesehen erstellen wir Ponys, die miteinander zusammenarbeiten müssen und Freunde sind. Dein Pony sollte
gewillt sein, als Teil eines Teams zu arbeiten und der Freund jedes anderen Ponycharakters zu sein. Sich ein Pony
auszusuchen, das nichts lieber mag als in Ruhe ein gutes Buch zu lesen, ist eine coole Idee, aber ein Pony, dass sich
ganz entschieden weigert, sein Haus zu verlassen, würde in jeder Spielsitzung ein ganz schönes Problem bedeuten!</Typography>
  </Wrapper>)
}

export default IntroStep
