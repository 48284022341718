import { blue, deepOrange, pink, purple, red } from "@material-ui/core/colors"
import { decomposeColor, emphasize, fade, lighten, rgbToHex } from "@material-ui/core/styles"

import {hexToHsl, hslToHex} from 'colors-convert'


// magenta is basically a more saturated pink. (please don't punch me for saying that)
const magenta = Object.fromEntries(Object.entries(pink).map(([key, color]) => {
  const hsl = hexToHsl(color)
  hsl.s = 100
  const newColor = hslToHex(hsl)

  return [key, newColor]
}))

console.log(magenta)

export type HarmonyType = {
  id: string
  name: string
  description: string
  color: any
  colorName: string
  examplePony: string
}

const allHarmonies : HarmonyType[] = [
  {
    id: "friendly",
    name: "Freundlichkeit",
    description: `Ponys, die mit diesem Element verbunden
    sind, sind die süßesten und sanftesten aller
    Wesen. Freundlichkeit kann knifflig sein, und nicht
    jedes Pony hat dieselbe Meinung darüber, was
    Freundlichkeit bedeutet. Solche Ponys müssen darauf
    achten, dass sie Freundlichkeit nicht mit Schwäche
    verwechseln.`.replace(/\n[ ]*/g, "\n"),
    colorName: "Pink",
    color: pink,
    examplePony: "Fluttershy",
  },
  {
    id: "generous",
    name: "Großzügigkeit",
    description: `Ponys, die mit diesem Element verbunden
    sind, haben die größten Herzen und
    sind jederzeit dazu bereit, ihren Freunden zu
    helfen und mit ihnen alles zu teilen, was sie
    besitzen. Solche Ponys müssen manchmal darauf
    achtgeben, dass andere ihre Großzügigkeit nicht
    für selbstverständlich nehmen, und leiden sehr
    darunter, zu geben, wenn nicht genug für alle da ist.`.replace(/\n[ ]*/g, "\n"),
    colorName: "Violett",
    color: purple,
    examplePony: "Rarity",
  },
  {
    id: "laugh",
    name: "Lachen",
    description: `Ponys, die mit diesem Element verbunden sind,
    sind die witzigsten und fröhlichsten Gefährten,
    die man sich nur wünschen kann – sie sehen immer
    alles positiv. Manchmal können Ponys, die sich dem
    Element des Lachens verschreiben, etwas Mühe damit
    haben, dafür zu sorgen, dass jeder ihren Humor mag,
    denn Lachen ist nur dann großartig, wenn alle lachen!`.replace(/\n[ ]*/g, "\n"),
    colorName: "Blau",
    color: blue,
    examplePony: "Pinkie Pie",
  },
  {
    id: "loyalty",
    name: "Treue",
    description: `Ponys, die mit diesem Element verbunden
    sind, sind die zuverlässigsten aller Freunde.
    Sie werden einem Freund in Not niemals von
    der Seite weichen und niemals ein gegebenes
    Versprechen brechen. Ein Pony, das sehr treu ist,
    kann möglicherweise in Schwierigkeiten kommen,
    wenn es seine Treue dem falschen Pony geschenkt
    hat oder wenn die Treue zwei Ponys gleichzeitig
    gegenüber nur schwer eingehalten werden kann!`.replace(/\n[ ]*/g, "\n"),
    colorName: "Rot",
    color: red,
    examplePony: "Rainbow Dash",
  },
  {
    id: "honest",
    name: "Ehrlichkeit",
    description: `Ponys, die mit diesem Element verbunden
    sind, kann man sein Leben anvertrauen.
    Sie sprechen immer aus, was sie denken, und
    verschleiern unter keinen Umständen die Wahrheit.
    Manchmal kann es schwer sein, die Wahrheit zu
    sagen, und sogar noch schwerer, die Wahrheit zu
    sagen, ohne ein Pony zu verletzen. Solche Ponys
    verstehen das besser als jedes andere Pony!`.replace(/\n[ ]*/g, "\n"),
    colorName: "Orange",
    color: deepOrange,
    examplePony: "Applejack",
  },
  {
    id: "magic",
    name: "Magie",
    description: `Ponys, die mit diesem Element verbunden sind,
    haben ein feines Gespür für das mächtigste aller
    Elemente – der Magie der Freundschaft. Magie ist das
    Element, das am schwersten zu verwenden ist, und
    kann nur existieren, wenn die anderen fünf Elemente
    anwesend sind. Solche Ponys tun sich schwer damit, all
    die anderen Elemente der Harmonie im Gleichgewicht
    zu halten, um die Freundschaft stark zu halten.`.replace(/\n[ ]*/g, "\n"),
    colorName: "Magenta",
    color: magenta,
    examplePony: "Twilight Sparkle",
  },
]

export default allHarmonies
