import { IconButton } from '@material-ui/core'
import React from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

type Props = {}

const handleChange = (event: any) => {
  if (event.target.files.length === 0) {
    console.warn("No file selected. Not updating data.")
    return
  }

  const file = event.target.files[0]
  const reader = new FileReader()
  reader.readAsText(file)
  reader.onload = () => { 
    if (reader.result === null) {
      console.error("Problem when reading the selecte file")
    } else {
      const jsonString = reader.result.toString()
      try {
        const obj = JSON.parse(jsonString)
        if (typeof(obj) !== "object") { throw new Error("Expected file to contain a json object, but it did not.")}

        // remove all keys
        var key = localStorage.key(0)
        while(key) {
          localStorage.removeItem(key)
          key = localStorage.key(0)
        }

        // import new values
        for (let key in obj) {
          const value = obj[key]
          localStorage.setItem(key, JSON.stringify(value))
        }

        window.location.reload()
      } catch (e) {
        console.error("Error when parsing uploaded file: " + e)
      }
    }
  }
  reader.onerror = (e) => {
    console.error(e)
  }
}

function Import(props: Props) {
  return <><IconButton
    aria-label="Pony exportieren"
    color="inherit"
    component="label"
  >
    <CloudUploadIcon />
    <input type="file" onChange={handleChange} hidden accept=".json" />
  </IconButton>
  </>

}

export default Import
