import React from 'react'

type Props = {
  setDataUri: (dataUri: string|undefined) => void
}

function ImagePicker({setDataUri}: Props) {
  const handleChange = (event: any) => {
    if (event.target.files.length === 0) {
      setDataUri(undefined)
      return
    }

    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => { 
      if (reader.result === null) {
        setDataUri(undefined)
      } else {
        setDataUri(reader.result.toString())
      }
    }
    reader.onerror = (e) => {
      console.error(e)
      setDataUri(undefined)
    }
  }

  return <input type="file" onChange={handleChange} hidden />
}

export default ImagePicker
