import { Button, makeStyles, Paper, Slider, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import React, { useEffect, useState } from 'react'
import Character, { DiceType } from '../model/Character'
import Help from '../components/Help'

type Props = {
  character: Character
  onFriendshipChange: (friendship: number) => void
}

const useStyles = makeStyles(theme => ({
  box: {
    overflow: "hidden",
    padding: theme.spacing(2, 2, 0, 2),
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  button: {
    fontFamily: '"GloriaHallelujah", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "2rem",
    lineHeight: "0.75em",
  },
}))

function FriendshipHelp() {
  return <Help title="Freundschaft">
    <Typography paragraph>Sprechen wir über das Wichtigste im Leben deines Ponys bei
      Tails of Equestria – Freundschaft!
      Wie Ausdauer auch ist Freundschaft kein
      Würfelwert. Stattdessen besitzt dein
      Ponycharakter eine kleine Menge sehr wertvoller
      und mächtiger Freundschaftssteine, die du
      jederzeit während deiner Abenteuer in Equestria
      einsetzen kannst.
    </Typography>
    <Typography variant="h5" paragraph>Freundschaftssteine verwenden</Typography>
    <Typography><strong>1 Freundschaftsstein zurückgeben: </strong> Du kannst du das Würfelergebnis ignorieren und noch einmal würfeln.</Typography>
    <Typography><strong>2 Freundschaftsstein zurückgeben: </strong> Du kannst du das Würfelergebnis ignorieren und die Probe noch einmal versuchen,
    aber diesmal würfelst du mit einem W20 statt des Würfels, den du zuletzt verwendet hast.</Typography>
    <Typography paragraph><strong>3 Freundschaftsstein zurückgeben: </strong> Du bestehst du die Probe oder gewinnst die Herausforderung automatisch – du brauchst nicht neuzuwürfeln!</Typography>
    <Typography variant="h5" paragraph>Die Geschichte ändern</Typography>
    <Typography paragraph>Freundschaftssteine werden nicht nur benutzt, um Würfelwürfe zu ändern. Du kannst sie auch benutzen, um den
    Spielleiter zu bitten, etwas, das in der Geschichte passiert, leicht zu ändern. Zum Beispiel bist du gerade auf dem
    Markt und musst wirklich dringend ein Seil kaufen, aber du hast nicht genug Geld dabei, um es dir leisten zu
    können. Du hast schon versucht, den Verkäufer anzuflehen, das Seil zu einem günstigeren Preis zu erhalten, aber
    deine Charme-Probe ist dir misslungen. Nun, du könntest den Spielleiter fragen, ob du einen Freundschaftsstein
    zurückgeben darfst, um so das Geld, das du brauchst, auf dem Boden zu finden ... durch einen glücklichen Zufall!
    </Typography>
    <Typography variant="h5" paragraph>Freundschaftssteine mischen</Typography>
    <Typography paragraph>Da gibt es noch etwas, das du beachten solltest: Immer wenn du Freundschaftssteine verwendest, kannst du sie
mit den Steinen deiner Freunde mischen, und je mehr du dies tust, desto mächtiger werden sie!</Typography>
    <Typography paragraph>Da dies die ultimative Kraft der Freundschaft ist – zu teilen, sich umeinander zu kümmern und sich zusammenzutun,
um Schwierigkeiten zu überwinden –, sind Steine, die auf diese Weise verwendet werden, mehr wert. Der
Spielleiter sollte dies bedenken, wenn er entscheidet, wie viele Steine du zurückgeben musst, um ein bestimmtes
Ergebnis zu erreichen.</Typography>
    <Typography variant="h5" paragraph>Freundschaftssteine verdienen</Typography>
    <Typography paragraph>Der Spielleiter kann dich mit Freundschaftssteinen belohnen, wenn du und deine Freundinnen
    wie wahre Freunde verhalten.
    </Typography>
    <Typography paragraph>
    Zum Beispiel sind du und deine Freunde auf der Großen Galloping-Gala in Canterlot, als du siehst, dass eine
    große Schale voller Orangensaft gerade dabei ist umzukippen und den gesamten Saft auf dem neuen Kleid deiner
    Ponyfreundin zu verschütten. Du springst vor und stellst dich dem Saft in den Weg, ruinierst dabei deine eigene
    Kleidung, aber du rettest dadurch die deiner Freundin, weil du weißt, wie viel ihr dieses Kleid bedeutet! Das ist
    ein großartiges Zeichen der Freundschaft, bei dem du auf großzügigste Weise etwas für deine Freunde opferst</Typography>
    <Typography paragraph>
    Eine weitere Möglichkeit Freundschaftssteine zu verdienen, ist deine Eigenheiten auf eine Art aus zu spielen, die dich und
    deine Freunde in eine missliche Lage bringen. Wenn dadurch eine spannende, interessante oder lustige Situation entsteht, kann
    der Spielleiter dich mit einem Freundschaftsstein belohnen.
    </Typography>
</Help>
}

function Friendship({character, onFriendshipChange}: Props) {
  const [friendship, setFriendship] = useState(character.friendship)
  useEffect(() => {
    setFriendship(character.friendship)
  }, [character, setFriendship])

  const classes = useStyles()

  const addFriendship = () => {
    const newFriendship = friendship + 1
    setFriendship(newFriendship)
    onFriendshipChange(newFriendship)
  }
  const removeFriendship = () => {
    const newFriendship = Math.max(friendship - 1, 0)
    setFriendship(newFriendship)
    onFriendshipChange(newFriendship)
  }

  return <Paper className={classes.box} style={{backgroundColor: red[100]}}>
    <Typography paragraph variant="h4" style={{color: red[900]}}>Freund&shy;schaft<FriendshipHelp /></Typography>
    <div className={classes.container}>
      <Button className={classes.button} variant="text" disabled={friendship === 0} onClick={removeFriendship}>-</Button>
      <Typography paragraph variant="body2" style={{color: red[900]}}>{character.friendship}</Typography>
      <Button className={classes.button} variant="text" onClick={addFriendship}>+</Button>
    </div>
  </Paper>
}

export default Friendship
