import React, { useEffect, useState } from 'react'
import { HarmonyType } from '../data/Harmony'
import Wrapper, { StepProps } from './Wrapper'
import allHarmonies from '../data/Harmony'
import { Button, makeStyles, Typography } from '@material-ui/core'
import MyMarkdown from '../layout/MyMarkdown'
import MyImageWithText from '../layout/MyImageWithText'

type HarmonyProps = {
  harmony?: HarmonyType
  setHarmony: (harmony: HarmonyType) => void
} & StepProps

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: "center",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(0, 2, 2, 0),
  }
}))

function HarmonyStep({setHarmony, harmony, ...props} : HarmonyProps) {
  // propagate selection instantly, so the design can be updated
  const classes = useStyles()

  const isValid = harmony !== undefined

  return (<Wrapper label="Harmonie" {...props} isValid={isValid}>
    <Typography paragraph>
      Du kannst dir frei eines der sechs Elemente der Harmonie für deinen Ponycharakter
      aussuchen. Beachte, dass dies keinerlei Regelauswirkungen hat. Stattdessen ist es
      ein Hilfsmittel, um dem Spielleiter und deinen Mitspielern zu zeigen, wie du dir die
      Persönlichkeit deines Ponys vorstellst.
    </Typography>
    <Typography paragraph>
      Keine Sorge – du brauchst dich nicht die ganze Zeit so zu verhalten wie die Ponys in den Beschreibungen zu
      deinem ausgesuchten Element. Das ist in Ordnung, solange das Verhalten deines Ponys nur oft genug seinem
      Element entspricht. Zum Beispiel ist Pinkie Pie die meiste Zeit fröhlich und lustig, ganz entsprechend dem Element
      des Lachens, für das sie geboren wurde. Manchmal kann sie aber auch ernst, wütend und sogar traurig sein!
    </Typography>
    {/* <Typography paragraph>
      Falls du irgendwann später dein Element der Harmonie ändern willst, sage es einfach dem Spielleiter und ändere
      es auf deinem Ponybogen – dein Pony hat soeben einen neuen Abschnitt seines Lebens begonnen, vielleicht als
      es sich von einem Teenagerpony zu einem erwachsenen Pony weiterentwickelt hat.
    </Typography> */}
    <Typography paragraph>
      Es macht großen Spaß, wenn alle Mitglieder der Gruppe einem anderen Element der Harmonie angehören, und
      kann auch für ein Abenteuer nützlich sein, wenn es zum Beispiel in einer bestimmten Situation wichtig ist, dass ein
      Pony dem richtigen Element angehört. Zum Beispiel könnte sich eine magische Truhe nur dann öffnen, wenn sie
      von einem Pony berührt wird, das dem Element der Großzügigkeit angehört, oder ein Wesen, dem ihr begegnet,
      könnte leichter zum Freund werden, wenn jemand dem Element der Freundlichkeit angehört, und so weiter.
      Vielleicht kannst du dich also mit deinen Freunden darauf einigen, dass ihr alle unterschiedliche Elemente der
      Harmonie für eure Ponycharaktere wählt. Allerdings macht es auch überhaupt nichts, wenn sich zwei oder mehr
      Charaktere dasselbe Element teilen.
    </Typography>
    <div className={classes.buttonContainer}>
      {allHarmonies.map(theHarmony => {
        return (<Button className={classes.button} key={theHarmony.id} onClick={() => setHarmony(theHarmony)} color={harmony?.id === theHarmony.id ? "primary" : "inherit"}>{theHarmony.name}</Button>)
      })}
    </div>
    {harmony && <div>
      <MyImageWithText src={`/images/harmony/${harmony.id}.jpg`}>
        <MyMarkdown>{harmony.description}</MyMarkdown>
        <Typography paragraph><strong>Farbe:</strong> <strong style={{color: harmony.color[900]}}>{harmony.colorName}</strong></Typography>
        <Typography paragraph><strong>Das letzte Pony, das das Element der {harmony.name} besessen hat:</strong> {harmony.examplePony}</Typography>
      </MyImageWithText>
      
    </div>}
  </Wrapper>)
}

export default HarmonyStep
