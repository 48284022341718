import React, { useEffect, useState } from 'react'
import { PonyType } from '../data/Pony'
import Wrapper, { StepProps } from './Wrapper'
import allPonies from '../data/Pony'
import { Button, makeStyles, Typography } from '@material-ui/core'
import MyMarkdown from '../layout/MyMarkdown'
import MyImageWithText from '../layout/MyImageWithText'

type PonyProps = {
  pony?: PonyType
  setPony: (pony: PonyType) => void
} & StepProps

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: "center",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(0, 2, 2, 0),
  }
}))

function PonyStep({setPony: setParentPony, pony: parentPony, ...props} : PonyProps) {
  const [pony, setPony] = useState(parentPony)
  useEffect(() => {
    setPony(parentPony)
  }, [parentPony, setPony])
  const classes = useStyles()

  const isValid = pony !== undefined
  const onSave = () => {
    if (pony === undefined) { return false }
    setParentPony(pony)
    return true
  }

  return (<Wrapper label="Pony" {...props} isValid={isValid} onSave={onSave}>
    <Typography paragraph>In diesem Kapitel erfährst du mehr über die drei Arten von Ponys in Equestria – Erdponys, Einhörner und Pegasi.
Wenn ihr das Spiel als Gruppe beginnt, ist es am besten, wenn du und deine Freunde euch unterschiedliche Arten
von Ponys aussucht – so könnt ihr euch auf die Stärken der anderen verlassen. Natürlich ist das keine strenge
Regel, und ihr könnt jede beliebige Mischung von Ponys in eurer Gruppe haben – sogar eine Gruppe, in der zum
Beispiel alle Einhörner spielen.</Typography>
    <div className={classes.buttonContainer}>
      {allPonies.map(thePony => {
        return (<Button 
          className={classes.button} 
          key={thePony.id} 
          onClick={() => setPony(thePony)} 
          color={pony === thePony ? "primary" : "inherit"}
          style={pony?.id === thePony.id ? {backgroundColor: pony.color[900]}: {}}
        >
          {thePony.name}
        </Button>)
      })}
    </div>
    { pony && <>
      <MyImageWithText src={`/images/pony/${pony.id}.jpg`}>
        <Typography paragraph variant="h4" style={{color: pony.color[900]}}>{pony.abstract}</Typography>
        <MyMarkdown>{pony.description}</MyMarkdown>
      </MyImageWithText>
    </>}
  </Wrapper>)
}

export default PonyStep
