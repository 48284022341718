import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flow-root",
  },
  img: {
    float: "right",
    border: theme.spacing(1) + "px solid #fff",
    borderRadius: theme.spacing(2),
    maxWidth: "50%",
    margin: theme.spacing(0, 0, 2, 2),
  }
}))

type Props = {
  src: string
  children: React.ReactNode
}

function MyImageWithText({src, children}: Props) {
  const classes = useStyles()

  return <div className={classes.root}>
    <img src={src} alt="" className={classes.img} />
    {children}
  </div>
}

export default MyImageWithText
