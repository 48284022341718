import React from 'react'
import { Button, fade, makeStyles, Step, StepContent, StepLabel, Typography } from '@material-ui/core'

export type StepProps = {
  step: number
  activeStep: number
  lastStep?: boolean
  setStep: (step: number) => void
  onFinish?: () => void
}

type WrapperProps = {
  label: string
  children: React.ReactNode
  onSave?: () => boolean
  isValid?: boolean
  onFinish?: () => void
} & StepProps

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    position: "relative",
  },
  prev: {
    float: "left",
    marginBottom: theme.spacing(1),
  },
  next: {
    float: "right",
    marginBottom: theme.spacing(1),
  },
  stepLabel: {
    color: fade(theme.palette.common.black, 0.3),
  },
  stepLabelActive: {
    // important, because in practice a two-class selector is used
    color: theme.palette.primary.main + " !important",
  },
  stepLabelCompleted: {
    // important, because in practice a two-class selector is used
    color: fade(theme.palette.common.black, 0.7) + " !important",
  },
  stepContent: {
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(1),
  }
}))

function Wrapper({step, activeStep, setStep, lastStep, isValid, label, children, onSave, onFinish, ...props}: WrapperProps) {
  lastStep = lastStep || false
  isValid = isValid === undefined ? true : isValid

  const classes = useStyles()

  return (<Step active={activeStep === step} completed={activeStep > step}>
    <StepLabel icon={step+1} classes={{
      label: classes.stepLabel,
      active: classes.stepLabelActive,
      completed: classes.stepLabelCompleted,
    }}><Typography variant="h4">{label}</Typography></StepLabel>
    <StepContent className={classes.stepContent} {...props}>
      <div>{children}</div>
      <div className={classes.buttonContainer}>
        { step > 0 && <Button className={classes.prev} variant="outlined" onClick={() => setStep(step - 1)}>Zurück</Button> }
        <Button className={classes.next} color="primary" disabled={!isValid} onClick={() => {
          if (onSave) {
            const result = onSave()
            if (!result) { return }
          }
          if (onFinish) {
            onFinish()
          }
          setStep(step + 1)
        }}>{lastStep ? "Fertig" : "Weiter"}</Button>
      </div>
    </StepContent>
    
  </Step>)
}

export default Wrapper
