import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

type Props = {}

const deleteAll = () => {
  var key = localStorage.key(0)
  while(key) {
    localStorage.removeItem(key)
    key = localStorage.key(0)
  }

  window.location.reload()
}

function DeleteAll(props: Props) {
  const [open, setOpen] = useState(false)
  return <><IconButton
    aria-label="show more"
    aria-haspopup="true"
    color="inherit"
    onClick={() => { setOpen(true) }}
  >
    <DeleteForeverIcon />
  </IconButton>
  <Dialog
    open={open}
    onClose={ () => setOpen(false)}
  >
    <DialogTitle>Willst du wirklich dein aktuelles Pony löschen und ein neues erstellen?</DialogTitle>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="default">
        Nein!
      </Button>
      <Button onClick={() => {deleteAll(); setOpen(false)} } color="primary">
        Ja!
      </Button>
    </DialogActions>
  </Dialog>
  </>

}

export default DeleteAll
