import { makeStyles, Paper, Slider, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Character from '../model/Character'
import Help from '../components/Help'

type Props = {
  character: Character
  onStaminaChange: (stamina: number) => void
}

const useStyles = makeStyles(theme => ({
  info: {
    overflow: "hidden",
    padding: theme.spacing(2, 2, 0, 2),
  },
}))

function StaminaHelp() {
  return <Help title="Ausdauer">
    <Typography paragraph>Die Ausdauer deines Ponycharakters basiert nicht auf einem Würfel. Stattdessen
      zeigt sie sich in Form von Ausdauerpunkten. Ausdauer ist ein Maß dafür, wie gesund
      dein Pony gerade ist, sowohl in Körper und Geist. Wenn du vollkommen frisch,
      ausgeruht und energiegeladen bist, sind deine Ausdauerpunkte auf dem Höchstwert.
    </Typography>
    <Typography variant="h5" paragraph>Ausdauer verlieren</Typography>
    <Typography paragraph>Wenn du umher reist und sehr müde wirst, dir wehtust oder du traurig oder verwirrt wirst,
verlierst du eine gewisse Menge an Ausdauerpunkten. Notiere dir auf deinem Ponybogen unter „Momentane
Ausdauer“ die Anzahl an Ausdauerpunkten, die du noch übrig hast.
    </Typography>
    <Typography variant="h5" paragraph>Null Ausdauer!</Typography>
    <Typography paragraph>
    Sollte deine Ausdauer je auf 0 oder weniger fallen, wird dein Pony ohnmächtig oder kann nicht weitergehen –
    die Erschöpfung übermannt dich und du brauchst sofort eine Rast! Dies kann ärgerlich sein, wenn es dich
    beispielsweise davon abhält, eine Aufgabe zu erledigen. Es kann auch zu Problemen führen, denn Bösewichte
    könnten dich gefangen nehmen, während du schläfst, und du könntest in einer Zelle oder einem Käfig wieder
    aufwachen! Aus diesem Grund ist es normalerweise keine gute Idee, alleine auf Abenteuer auszuziehen – Freunde
    um dich zu haben, die dir helfen können, wenn du niedergeschlagen oder in Schwierigkeiten bist, ist das Beste!
    </Typography>
    <Typography variant="h5" paragraph>Ausdauer erholen</Typography>
    <Typography paragraph>
    Ausdauer kann sich normalerweise auf natürliche Weise erholen, etwa durch Rast, durch gesundes Essen und
    Trinken oder sogar durch Magie mittels Tränken oder Begabungen. Alle diese Arten
    der Heilung zählen eine bestimmte Menge Ausdauerpunkte auf deinen momentanen Wert, bis maximal zu deinen
    Höchsten Ausdauerpunkten.
    </Typography>
  </Help>
}

function Stamina({character, onStaminaChange}: Props) {
  const [value, setValue] = useState(character.stamina)
  useEffect(() => {
    setValue(character.stamina)
  }, [character, setValue])

  const classes = useStyles()

  const handleChange = (event: any, value: number|number[]) => {
    onStaminaChange(value as number)
  }

  return <Paper className={classes.info}>
    <Typography paragraph variant="h4" color="primary">Ausdauer<StaminaHelp /></Typography>
    <Slider 
      min={0}
      value={value}
      max={character.maxStamina}
      step={1}
      marks={true}
      valueLabelDisplay="auto"
      onChange={(e, v) => setValue(v as number)}
      onChangeCommitted={handleChange}
    />
  </Paper>
}

export default Stamina
