import React, { useState } from 'react';
import { AppBar, Container, makeStyles, Stepper, Toolbar, Typography } from '@material-ui/core';
import HelloStep from './steps/HelloStep';
import PonyStep from './steps/PonyStep';
import { PonyType } from './data/Pony';
import { HarmonyType } from './data/Harmony';
import HarmonyStep from './steps/HarmonyStep';
import StrongStep from './steps/StrongStep';
import SkillStep from './steps/SkillStep';
import allSkills, { SkillType } from './data/Skills';
import { QuirkType } from './data/Quirks';
import QuirkStep from './steps/QuirkStep';
import FinalStep from './steps/FinalStep';
import ConfirmStep from './steps/ConfirmStep';
import IntroStep from './steps/IntroStep';
import MyTheme from './layout/MyTheme';
import { pink } from '@material-ui/core/colors';
import Character from './model/Character';
import Spinner from './layout/Spinner';
import usePersistedState from './hooks/usePersistentState';
import Import from './components/Import';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: theme.spacing(2,"auto"),
      boxShadow: "0 0 20px 0px #fff"
    },
    grow: {
      flexGrow: 1
    },
  }
})

type Props = {
  onSave: (char: Character) => void
}

function Wizard({onSave} : Props) {
  const [ponyName, setPonyName] = usePersistedState<string>('wizard.ponyName')
  const [playerName, setPlayerName] = usePersistedState<string>('wizard.playerName')
  const [ponyType, setPonyType] = usePersistedState<PonyType>('wizard.ponyType')
  const [harmony, setHarmony] = usePersistedState<HarmonyType>('wizard.harmony')
  const [ratherStrong, setRatherStrong] = usePersistedState<boolean>('wizard.strong')
  const [skill, setSkill] = usePersistedState<SkillType>('wizard.skill')
  const [skillOption, setSkillOption] = usePersistedState<string|undefined>('wizard.skillOption')
  const [quirk, setQuirk] = usePersistedState<QuirkType>('wizard.quirk')
  const [beautyPatch, setBeautyPatch] = usePersistedState<string|undefined>('wizard.beauty')
  const [beautyEmoji, setBeautyEmoji] = usePersistedState<string|undefined>('wizard.beautyEmoji')
  const [beautyImage, setBeautyImage] = usePersistedState<string|undefined>('beautyImage')
  const [image, setImage] = usePersistedState<string|undefined>('image')

  const [step, setStep] = useState(0)

  const classes = useStyles()

  const handleFinish = () => {
    if (!ponyName || !playerName || !ponyType || !harmony) {
      console.warn("Could not generate pony character. This should usually not happen and might put you in an invalid step.")
      return 
    }

    const character = new Character(ponyName, playerName, ponyType, harmony)
    if (ponyType.id === "earth") { character.upgradeBody() }
    // add class skills
    allSkills.forEach(skill => {
      if (skill.isInitial && skill.requires === ponyType.id) {
        // initial skills start as a W6, so add it twice
        character.addSkill(skill.name, skill.description)
        character.addSkill(skill.name, skill.description)
      }
    })
    if (ratherStrong === true) { character.upgradeBody() }
    if (ratherStrong === false) { character.upgradeSpirit() }

    if (skill) {
      const name = skillOption !== undefined ? `${skill.name} (${skillOption})` : skill.name
      // i feel that a W4 for your core ability is a bit low. So add it twice
      character.addSkill(name, skill.description)
      character.addSkill(name, skill.description)
    }
    if (quirk) {
      character.addQuirk(quirk)
    }
    character.beautyPatch = beautyPatch
    character.beautyEmoji = beautyEmoji

    character.recover()
    onSave(character)
  }

  return (<React.Fragment>
    <MyTheme primary={harmony?.color || pink}>
      <Container maxWidth="md" disableGutters={true} className={classes.container}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h1">Tails of Equestria</Typography>
            <div className={classes.grow}></div>
            <Import />
          </Toolbar>

        </AppBar>
        { step > 8 ? <Spinner /> : 
          <Stepper activeStep={step} orientation="vertical">
            <HelloStep step={0} activeStep={step} setStep={setStep} name={playerName} setName={setPlayerName} />
            <IntroStep step={1} activeStep={step} setStep={setStep} name={playerName} />
            <PonyStep step={2} activeStep={step} setStep={setStep} setPony={setPonyType} pony={ponyType} />
            <HarmonyStep step={3} activeStep={step} setStep={setStep} setHarmony={setHarmony} harmony={harmony} />
            <StrongStep step={4} activeStep={step} setStep={setStep} isStrong={ratherStrong} setStrong={setRatherStrong} />
            <SkillStep step={5} activeStep={step} setStep={setStep} skill={skill} setSkill={setSkill} skillOption={skillOption} setSkillOption={setSkillOption} pony={ponyType} />
            <QuirkStep step={6} activeStep={step} setStep={setStep} quirk={quirk} setQuirk={setQuirk} />
            <FinalStep 
              step={7} 
              activeStep={step} 
              setStep={setStep} 
              name={ponyName} 
              setName={setPonyName} 
              beautyPatch={beautyPatch} 
              setBeautyPatch={setBeautyPatch} 
              beautyEmoji={beautyEmoji}
              setBeautyEmoji={setBeautyEmoji}
              beautyPatchImage={beautyImage}
              setBeautyPatchImage={setBeautyImage}
              image={image}
              setImage={setImage}
              skill={skill} 
              skillOption={skillOption} 
              quirk={quirk} 
              harmony={harmony} 
              ponyType={ponyType}
            />
            <ConfirmStep
              step={8} 
              activeStep={step} 
              setStep={setStep}
              lastStep={true}
              onFinish={handleFinish}
              playerName={playerName}
              name={ponyName}
              skill={skill}
              skillOption={skillOption}
              ratherStrong={ratherStrong}
              harmony={harmony}
              quirk={quirk}
              ponyType={ponyType}
            />
          </Stepper>
        }
      </Container>
    </MyTheme>
      
    </React.Fragment>
  )
}

export default Wizard;
