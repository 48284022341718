import { fade, IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import usePersistedState from '../hooks/usePersistentState'
import MyImage from '../layout/MyImage'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ImagePicker from '../components/ImagePicker'

type Props = {
  className?: string
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  button: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(2),
  },
  placeholder: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    border: theme.spacing(1) + "px solid #fff",
    borderRadius: theme.spacing(2),
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
  dummyImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    opacity: 0.1,
  },
}))

function Portrait({className}: Props) {
  const [image, setImage] = usePersistedState<string|undefined>('image')

  const classes = useStyles()
  
  if (image) {
    return <div className={classes.root}>
      <MyImage className={className} src={image} />
      <IconButton className={classes.button} onClick={() => {
          setImage(undefined)
        }} aria-label="Entfernen"><DeleteIcon /></IconButton>
    </div>
  } else {
    return <div className={classes.root}>
      <div className={classes.placeholder}><img  className={classes.dummyImage} src="/images/pony_silhouette.svg" alt="" /></div>
      <IconButton component="label" className={classes.button} aria-label="Bild hinzufügen"><AddCircleIcon /><ImagePicker setDataUri={setImage}/></IconButton>
    </div>
  }

}

export default Portrait
