import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type Props = {
  title?: string
  children: React.ReactNode
}

function Help({title, children}: Props) {
  const [open, setOpen] = useState(false)

  return <>
    <IconButton onClick={() => {setOpen(true)}}><HelpOutlineIcon /></IconButton>
    <Dialog
      open={open}
      onClose={ () => setOpen(false)}
    >
      { title && <DialogTitle>{title}</DialogTitle> }
      <DialogContent><DialogContentText>{children}</DialogContentText></DialogContent>
      <DialogActions>
        <Button onClick={() => {setOpen(false)} } color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  </>
}

export default Help
