import { Typography } from '@material-ui/core'
import React from 'react'
import { HarmonyType } from '../data/Harmony'
import { PonyType } from '../data/Pony'
import { QuirkType } from '../data/Quirks'
import { SkillType } from '../data/Skills'
import BorderBox from '../layout/BorderBox'
import Wrapper, { StepProps } from './Wrapper'

type Props = {
  playerName?: string
  name?: string
  skill?: SkillType
  skillOption?: string
  ratherStrong?: boolean
  harmony?: HarmonyType
  quirk?: QuirkType
  ponyType?: PonyType
} & StepProps

function ConfirmStep({playerName, name, skill, skillOption, ratherStrong, harmony, quirk, ponyType, ...props}: Props) {
  return (<Wrapper label="Bestätigung" {...props}>
    <Typography variant="body2" paragraph>Du hast es fast geschafft, {playerName}.</Typography>
    <Typography paragraph>
      Lies dir noch einmal durch, welches Pony du spielen wirst, bestätige über den Knopf mit
      der Aufschrift "Fertig", und schon ist dein Pony bereit sich ins erste Abenteuer zu stürzen.
    </Typography>
    <BorderBox>
    <Typography paragraph>
      <strong>{name}</strong> ist ein<> </>
      <strong>{ratherStrong === true && "starkes"}{ratherStrong === false && "schlaues"}</strong><> </>
      <strong>{ponyType?.name}</strong>, dem <strong>{harmony?.name}</strong> wichtig ist.
    </Typography>
    <Typography paragraph>
      Dein Pony ist für die Begabung <strong>{skill?.name}{skillOption && (` (${skillOption})`)}</strong> bekannt
      und gerät gelegentlich durch die Eigenheit <strong>{quirk?.name}</strong> in Schwierigkeiten. Aber mit Freunden
      lässt sich jede Schwierigkeit meistern.
    </Typography>
    </BorderBox>
  </Wrapper>)
}

export default ConfirmStep
