import React, { useState } from 'react';
import Character from './Character';
import Wizard from './Wizard';
import CharacterObj, {CharacterData} from './model/Character';
import usePersistedState from './hooks/usePersistentState';

function App() {
  const [characterData, setCharacterData] = usePersistedState<CharacterData>('character')
  const character = characterData === undefined ? undefined : CharacterObj.fromJson(characterData)

  const handleCharacterSave = (char : CharacterObj) => {
    console.log(char)
    setCharacterData(char.toJson())
  }

  if (character !== undefined) {
    return <Character character={character} onCharacterChange={handleCharacterSave}></Character>
  } else {
    return <Wizard onSave={handleCharacterSave}></Wizard>
  }
}

export default App;
