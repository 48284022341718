import allHarmonies, { HarmonyType } from "../data/Harmony"
import allPonies, { PonyType } from "../data/Pony"
import { QuirkType } from "../data/Quirks"

export type DiceType = 4 | 6 | 8 | 10 | 12 | 20

const upgradeDice = (dice: DiceType): DiceType => {
  switch(dice) {
    case 4: return 6
    case 6: return 8
    case 8: return 10
    case 10: return 12
    case 12: return 20
    default: throw new Error(`Upgrading a stat beyond 20 is not supported.`)
  }
}

export type Skill = {
  stat: DiceType
  name: string
  description: string
}

// represents what is persisted
export class CharacterData {
  ponyName: string = ""
  playerName: string = ""
  beautyPatch?: string
  beautyEmoji?: string
  ponyType?: PonyType
  harmony?: HarmonyType
  skills: Skill[] = []
  quirks: QuirkType[] = []

  level: number = 1
  stamina: number = 0
  friendship: number = 0
  bodyStat: DiceType = 4
  spiritStat: DiceType = 4
  charmeStat: DiceType = 6
}

// represents a character sheet
class Character {
  private data = new CharacterData()

  get ponyName() { return this.data.ponyName }
  set ponyName(ponyName: string) { this.data.ponyName = ponyName }
  get playerName() { return this.data.playerName }
  set playerName(playerName: string) { this.data.playerName = playerName }
  get beautyPatch() { return this.data.beautyPatch }
  set beautyPatch(beautyPatch: string|undefined) { this.data.beautyPatch = beautyPatch }
  get beautyEmoji() { return this.data.beautyEmoji }
  set beautyEmoji(beautyEmoji: string|undefined) { this.data.beautyEmoji = beautyEmoji }
  get ponyType() { 
    if (!this.data.ponyType) throw new Error("Not implemented!")
    return this.data.ponyType }
  set ponyType(ponyType: PonyType) { this.data.ponyType = ponyType }
  get harmony() { 
    if (!this.data.harmony) throw new Error("Not implemented!")
    return this.data.harmony 
  }
  set harmony(harmony: HarmonyType) { this.data.harmony = harmony }
  get level() { return this.data.level }
  set level(level: number) { this.data.level = level }
  get stamina() { return this.data.stamina }
  set stamina(stamina: number) { this.data.stamina = stamina }
  get friendship() { return this.data.friendship }
  set friendship(friendship: number) { this.data.friendship = friendship }
  get bodyStat() { return this.data.bodyStat }
  set bodyStat(bodyStat: DiceType) { this.data.bodyStat = bodyStat }
  get spiritStat() { return this.data.spiritStat }
  set spiritStat(spiritStat: DiceType) { this.data.spiritStat = spiritStat }
  get charmeStat() { return this.data.charmeStat }
  set charmeStat(charmeStat: DiceType) { this.data.charmeStat = charmeStat }
  get maxStamina() {
    return this.data.bodyStat + this.data.spiritStat // see p43
  }

  get skills() { return this.data.skills }
  get quirks() { return this.data.quirks }

  get colorScheme() { return this.data.harmony?.color}

  constructor(ponyName: string, playerName: string, ponyType: PonyType, harmony: HarmonyType) {
    this.data.ponyName = ponyName
    this.data.playerName = playerName
    this.data.ponyType = ponyType
    this.data.harmony = harmony
  }

  recover() {
    this.data.stamina = this.maxStamina
  }

  upgradeBody() { this.data.bodyStat = upgradeDice(this.data.bodyStat) }
  upgradeSpirit() { this.data.spiritStat = upgradeDice(this.data.spiritStat) }
  upgradeCharme() { this.data.charmeStat = upgradeDice(this.data.charmeStat) }

  // will add the skill - or, if it already exists - upgrade it
  addSkill(name: string, description: string) {
    const idx = this.data.skills.findIndex(skill => skill.name === name)

    if (idx === -1) {
      // new skill
      this.data.skills.push({stat: 4, name, description})
    } else {
      this.data.skills[idx] = {stat: upgradeDice(this.data.skills[idx].stat), name, description}
    }
  }

  addQuirk(quirk: QuirkType) {
    this.data.quirks.push(quirk)
  }

  toJson() : CharacterData {
    return this.data
  }

  clone() : Character {
    // in order to use in a state the *object reference* must change
    const data = {...this.data}
    return Character.fromJson(data)
  }

  static fromJson(data: CharacterData) : Character {
    // the values here don't matter, because we override the data
    const character = new Character("dummy", "dummy", allPonies[0], allHarmonies[0])
    character.data = data

    return character
  }
}

export default Character
