import { Grid, makeStyles, Paper, Slider, Typography } from '@material-ui/core'
import { orange, pink, purple } from '@material-ui/core/colors'
import React, { useEffect, useState } from 'react'
import Character, { DiceType } from '../model/Character'
import Help from '../components/Help'

type StatProps = {
  label: React.ReactNode
  dice: DiceType
  color: any
}

const useStyles = makeStyles(theme => ({
  box: {
    overflow: "hidden",
    padding: theme.spacing(2, 2, 0, 2),
  },
  dice: {
    textAlign: "center",
  },
}))

function Stat({label, dice, color}: StatProps) {
  const classes = useStyles()
  return <Grid item xs={4}><Paper style={{backgroundColor: color[100]}} className={classes.box}>
    <Typography paragraph style={{color: color[900]}} variant="h4" color="primary">{label}</Typography>
    <Typography className={classes.dice} paragraph variant="body2">W{dice}</Typography>
  </Paper></Grid>
}

function BodyHelp() {
  return <Help title="Körper">
    <Typography paragraph>Das Merkmal Körper zeigt, wie stark du bist – wie groß das Gewicht ist, dass du
      anheben oder tragen kannst, zum Beispiel. Es ist auf ein Maß dafür, wie robust du bist,
      wie lange du rennen kannst, wie leicht du dich erkältest und so weiter. Darüber hinaus
      zeigt es, wie wendig du bist, wenn du dich bewegst, wie gut du Dingen ausweichen
      und wie gut du balancieren kannst, daher ist dieser Wert nützlich, wenn du einen
      Hindernislauf machst oder deine Tanzkünste unter Beweis stellen willst.
  </Typography>
  </Help>
}

function SpiritHelp() {
  return <Help title="Geist">
    <Typography paragraph>Das Merkmal Geist zeigt, wie intelligent dein Ponycharakter ist, wie gut du dich an
      Dinge erinnern und wie gut du lernen und eine Diskussion gewinnen kannst. Das
      Merkmal Geist ist auch nützlich, um Rätsel zu lösen, den Weg aus einem Labyrinth
      heraus zu finden und andere Sprachen zu sprechen. Darüber hinaus verfallen Ponys
      mit einem hohen Geist-Wert nicht so leicht in Panik und werden nicht so schnell
      traurig oder ängstlich. Sie können sich zum Weitermachen antreiben, wo andere
      vielleicht aufgeben.
    </Typography>
    <Typography paragraph>
      Das Merkmal Geist zeigt auch, wie gut das Feingefühl eines Ponys ist. Ein Pony mit einem
      guten Geist-Wert kann dort weitermachen, wo andere zu aufgewühlt sind, es kann erraten,
      woran sie denken und was sie als nächstes tun werden – sieh es als eine Art sechsten Sinn ... oder sogar einen
      Pinkie-Sinn!
    </Typography>
  </Help>
}

function CharmeHelp() {
  return <Help title="Charme">
    <Typography paragraph>Bei Charme geht es nicht nur darum, wie hübsch oder niedlich du bist. Er sagt etwas
      über deine Persönlichkeit aus – wie gern dich andere Leute mögen, wie gut sie auf
      dich hören und wie gern sie dein Freund sein und Zeit mit dir verbringen wollen.
      Ponys mit viel Charme werden von allen anderen Ponys gemocht, da sie immer nett
      sind und auf die Gefühle von anderen Rücksicht nehmen.
    </Typography>
    <Typography paragraph>Dieses Merkmal ist der Wert, der am schwierigsten zu messen ist, und kann bei vielen
      verschiedenen Ponys sehr unterschiedlich sein. Man könnte zum Beispiel sagen, dass
      sowohl Rarity als auch Fluttershy viel Charme haben, aber er zeigt sich bei beiden auf
      sehr unterschiedliche Weise. Charme hilft dabei, Freundschaften mit anderen Ponys und
      Wesen zu schließen, die dir auf deinen Abenteuern begegnen, und die Meinung anderer zu beeinflussen, etwa
      wenn du mit einem Ladenbesitzer auf dem Markt über den Preis eines Korbs Kirschen diskutierst.
    </Typography>
  </Help>
}

type Props = {
  character: Character
}

function Stats({character}: Props) {
  return <Grid container spacing={2}>
    <Stat label={<>Körper<BodyHelp /></>} dice={character.bodyStat} color={orange} />
    <Stat label={<>Geist<SpiritHelp /></>} dice={character.spiritStat} color={purple} />
    <Stat label={<>Charme<CharmeHelp /></>} dice={character.charmeStat} color={pink} />
  </Grid>
}

export default Stats
