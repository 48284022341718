import { IconButton } from '@material-ui/core'
import React from 'react'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import triggerDownload from 'js-file-download'

type Props = {}

const handleDownload = () => {
  const data: any = {}

  for(let i=0; i<localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key) {
      let content = localStorage.getItem(key)
      
      if (typeof(content) === "string") {
        try {
          content =  JSON.parse(content)
        } catch (e) {
          console.warn(e)
        }
      }
      data[key] = content
    }
  }

  const theData = JSON.stringify(data, null, 2)

  triggerDownload(theData, 'my-pony.json')
}

function Export(props: Props) {
  return <><IconButton
    aria-label="Pony exportieren"
    color="inherit"
    onClick={handleDownload}
  >
    <CloudDownloadIcon />
  </IconButton>
  </>

}

export default Export
