import { Typography } from '@material-ui/core';
import React from 'react';
import Markdown from 'markdown-to-jsx';

const options = {
    overrides: {
        a: {
            props: {
                target: "_blank",
                rel: "noopener noreferrer",
            }
        },
        p: {
            component: Typography,
            props: {
                paragraph: true,
            },
        },
        h1: {
            component: Typography,
            props: {
                paragraph: true,
                variant: "h1",
            },
        },
        h2: {
            component: Typography,
            props: {
                paragraph: true,
                variant: "h2",
            },
        },
        h3: {
            component: Typography,
            props: {
                paragraph: true,
                variant: "h3",
            },
        },
        h4: {
            component: Typography,
            props: {
                paragraph: true,
                variant: "h4",
            },
        },
        h5: {
            component: Typography,
            props: {
                paragraph: true,
                variant: "h5",
            },
        },
        h6: {
            component: Typography,
            props: {
                paragraph: true,
                variant: "h6",
            },
        },
    }
}
type Props = {
    children: string
}

function MyMarkdown({children}: Props) {
    return (
        <Markdown options={options}>{children}</Markdown>
    )
}

export default MyMarkdown;